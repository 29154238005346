import * as RenderConst from './RenderConst';

/**
 * TextureLayer is an abstract concept which is used to describe
 * how a render display is made of. Basically, in our project, here
 * are total 4 kinds of texture layers:
 * 1. video texture layer
 * 2. sharing texture layer
 * 3. watermark texture layer
 * 4. cursor texture layer
 *
 * For a render display, it is made of one or several texture layers.
 * TextureLayer is a good concept, for a round of rendering, we can
 * collect all the texture layers with the same z order to render. A good
 * way to improve performance via WebGPU renderer.
 */
class TextureLayer {
  #index = 0;
  #zIndex = -1;
  #width = 0;
  #height = 0;
  #texLayerType = -1;
  #rawData = null;
  #texType = -1;
  #texGroup = null;
  #uvCoords = null;
  #viewport = null;
  #isLocked = false;
  #pendingVideoFrame = null;
  #uniformBuffer = null;
  #clearColorUniformBuffer = null;
  #uvCoordsBuffer = null;
  #texBufferGroup = null;
  #isNew = false;
  #colorFormat = '';

  constructor(index, zIndex) {
    this.#index = index;
    this.#zIndex = zIndex;
  }

  getIndex() {
    return this.#index;
  }

  lock() {
    this.#isLocked = true;
  }

  unlock() {
    this.#isLocked = false;
  }

  isLocked() {
    return this.#isLocked;
  }

  getZIndex() {
    return this.#zIndex;
  }

  setWidth(width) {
    this.#width = width;
  }

  setHeight(height) {
    this.#height = height;
  }

  getWidth() {
    return this.#width;
  }

  getHeight() {
    return this.#height;
  }

  getRawData() {
    return this.#rawData;
  }

  setRawData(rawData) {
    this.#rawData = rawData;
  }

  setIsNew(isNew) {
    this.#isNew = isNew;
  }

  isNew() {
    return this.#isNew;
  }

  setColorFormat(colorFormat) {
    this.#colorFormat = colorFormat;
  }

  getColorFormat() {
    return this.#colorFormat;
  }

  setPendingVideoFrame(videoFrame) {
    if (this.#pendingVideoFrame) {
      this.#pendingVideoFrame.close();
      this.#pendingVideoFrame = null;
    }

    this.#pendingVideoFrame = videoFrame;
  }

  clearPendingVideoFrame() {
    if (this.#pendingVideoFrame) {
      this.#pendingVideoFrame.close();
      this.#pendingVideoFrame = null;
    }
  }

  setTextureLayerType(texLayerType) {
    this.#texLayerType = texLayerType;
  }

  getTextureLayerType() {
    return this.#texLayerType;
  }

  setTextureType(texType) {
    this.#texType = texType;
  }

  getTextureType() {
    return this.#texType;
  }

  getPendingVideoFrame() {
    return this.#pendingVideoFrame;
  }

  getUVCoords() {
    return this.#uvCoords;
  }

  setUVCoords(uvCoords) {
    this.#uvCoords = uvCoords;
  }

  getUVCoordsBuffer() {
    return this.#uvCoordsBuffer;
  }

  setUVCoordsBuffer(buffer) {
    this.#uvCoordsBuffer = buffer;
  }

  evalViewport(x, y, w, h, canvasHeight) {
    if (!this.#viewport) {
      this.#viewport = {};
    }

    this.#viewport.x = Math.floor(x);
    this.#viewport.w = Math.floor(w);
    this.#viewport.h = Math.floor(h);

    // convert Y from GL viewport to WebGPU viewport
    if (this.#texLayerType == RenderConst.TEX_LAYER_TYPE.BASE_LAYER) {
      this.#viewport.y = Math.floor(canvasHeight - (y + h));
    } else {
      this.#viewport.y = Math.floor(y);
    }

    // fix bug: if x/y is smaller than 0, we should set it to 0 as default value
    // Viewport in WebGPU can't be negative.
    if (this.#viewport.x < 0) {
      this.#viewport.x = 0;
    }

    if (this.#viewport.y < 0) {
      this.#viewport.y = 0;
    }

    this.#viewport.minDepth = 0;
    this.#viewport.maxDepth = 1;
  }

  setViewport(viewport) {
    this.#viewport = viewport;
  }

  getViewport() {
    return this.#viewport;
  }

  getTextureGroup() {
    return this.#texGroup;
  }

  setTextureGroup(texGroup) {
    this.#texGroup = texGroup;
  }

  setUniformBuffer(uniformBuffer) {
    this.#uniformBuffer = uniformBuffer;
  }

  getUniformBuffer() {
    return this.#uniformBuffer;
  }

  setClearColorUniformBuffer(uniformBuffer) {
    this.#clearColorUniformBuffer = uniformBuffer;
  }

  getClearColorUniformBuffer() {
    return this.#clearColorUniformBuffer;
  }

  setTextureBufferGroup(bufferGroup) {
    this.#texBufferGroup = bufferGroup;
  }

  getTextureBufferGroup() {
    return this.#texBufferGroup;
  }

  destroyTextureBufferGroup() {
    if (this.#texBufferGroup) {
      this.#texBufferGroup = null;
    }
  }

  recycle(resMgr) {
    if (resMgr) {
      this.destroyTextureBufferGroup();
      resMgr.destroyTextureGroup(this);
    }

    if (this.#pendingVideoFrame) {
      this.#pendingVideoFrame.close();
      this.#pendingVideoFrame = null;
    }

    if (this.#uniformBuffer) {
      this.#uniformBuffer.destroy();
      this.#uniformBuffer = null;
    }

    if (this.#clearColorUniformBuffer) {
      this.#clearColorUniformBuffer.destroy();
      this.#clearColorUniformBuffer = null;
    }

    this.#index = -1;
    this.#zIndex = -1;
    this.#texLayerType = RenderConst.TEX_LAYER_TYPE.UNKNOWN;
    this.#rawData = null;
    this.#texType = RenderConst.TEX_TYPE.UNKNOWN;
    this.#uvCoords = null;
    this.#viewport = null;
    this.#isLocked = false;
    this.#isNew = false;
    this.#colorFormat = '';
  }
}

export default TextureLayer;
