import {
  WCL_TROUBLESHOOTING_INFO,
  DOWNLOAD_WASM_FROM_MAIN_THREAD,
  MULTIVIEW_WEBGL_CONTEXT_LOST,
  CURRENT_DECODE_VIDEO_QUALITY,
  AUDIO_MONITOR_LOG,
  CURRENT_DECODE_VIDEO_FPS,
  WEBGL_CONTEXT_CREATE_FAILED,
  GLOBAL_TRACING_LOG,
} from './consts';

import globalTracingLogger from '../../common/globalTracingLogger';

export function GET_HEAP_I8() {
  if (self.GROWABLE_HEAP_I8) {
    return self.GROWABLE_HEAP_I8();
  }
  return Module.HEAP8;
}

export function GET_HEAP_U8() {
  if (self.GROWABLE_HEAP_U8) {
    return self.GROWABLE_HEAP_U8();
  }
  return Module.HEAPU8;
}

export function GET_HEAP_I16() {
  if (self.GROWABLE_HEAP_I16) {
    return self.GROWABLE_HEAP_I16();
  }
  return Module.HEAP16;
}

export function GET_HEAP_U16() {
  if (self.GROWABLE_HEAP_U16) {
    return self.GROWABLE_HEAP_U16();
  }
  return Module.HEAPU16;
}

export function GET_HEAP_I32() {
  if (self.GROWABLE_HEAP_I32) {
    return self.GROWABLE_HEAP_I32();
  }
  return Module.HEAP32;
}

export function GET_HEAP_U32() {
  if (self.GROWABLE_HEAP_U32) {
    return self.GROWABLE_HEAP_U32();
  }
  return Module.HEAPU32;
}

export function GET_HEAP_F32() {
  if (self.GROWABLE_HEAP_F32) {
    return self.GROWABLE_HEAP_F32();
  }
  return Module.HEAPF32;
}

// Defining on self so it can be used from Emscripten's Module["instantiateWasm"]
export async function downloadAndInstantiateWebAssembly(
  imports,
  successCallback
) {
  // WASM should be downloaded (and compiled) on main thread to avoid multiple parallel downloads
  try {
    // This is assigned to worker variable to be used when sharing module with thread workers
    const moduleOrArrayBuffer = await new Promise((resolve, reject) => {
      const listenForDownloadMessage = (e) => {
        let data = e.data;
        if (data.command === 'DOWNLOAD_WASM_FROM_MAIN_THREAD_OK') {
          add_monitor('DE');
          self.removeEventListener('message', listenForDownloadMessage);
          resolve(data.data);
        } else if (data.command === 'DOWNLOAD_WASM_FROM_MAIN_THREAD_FAILED') {
          self.removeEventListener('message', listenForDownloadMessage);
          reject(
            new Error(
              `Failed to download WASM file: ${wasmUrl} from main thread`
            )
          );
        }
      };
      self.addEventListener('message', listenForDownloadMessage);
      add_monitor('DS');
      postMessage({
        status: DOWNLOAD_WASM_FROM_MAIN_THREAD,
        url: wasmUrl,
      });
    });

    let instantiateResult = await WebAssembly.instantiate(
      moduleOrArrayBuffer,
      imports
    );

    if (instantiateResult.instance) {
      self.wasmModuleToShare = instantiateResult.module;
      successCallback(instantiateResult.instance);
    } else {
      self.wasmModuleToShare = moduleOrArrayBuffer;
      successCallback(instantiateResult);
    }
  } catch (e) {
    globaltracing_error(
      `E:H Failed to download and instantiate WASM file: ${wasmUrl}`,
      e
    );
  }
}

const isMainWorker = typeof window === 'undefined' ? false : true;

export function Log_Error(message, errorEvent = null) {
  if (isMainWorker) {
    // in main thread, log directly
    globalTracingLogger.error(message, errorEvent);
  } else {
    // in worker, log via postMessage
    globaltracing_error(message, errorEvent);
  }
}

export function globaltracing_error(errorMessage, errorEvent = null) {
  if (errorEvent instanceof Error || errorEvent instanceof ErrorEvent) {
    // On Safari, Error cannot be cloned, so just send string.
    // ErrorEvent can never be cloned.
    errorMessage +=
      'Message: ' +
      errorEvent?.message +
      ' Stack: ' +
      (errorEvent?.error?.stack ?? errorEvent?.stack);

    errorEvent = null;
  }

  postMessage({
    status: GLOBAL_TRACING_LOG,
    errorMessage,
    errorEvent,
  });
}

export function add_monitor(log) {
  postMessage({
    status: WCL_TROUBLESHOOTING_INFO,
    data: log,
  });
}

export function add_audio_monitor_log(log, port = null) {
  if (port)
    return port.postMessage({
      status: AUDIO_MONITOR_LOG,
      data: log,
    });
  postMessage({
    status: AUDIO_MONITOR_LOG,
    data: log,
  });
}

/**
 * FIXME: replaceCanvas is a fix for MTR-W's contextlost, and have some problems.
 * From now on we handle it with contextrestore.
 * so replaceCanvas is always set false.
 * modified by lambert.an@zoom.us
 */
export function multiview_webgl_monitor(canvasId, replaceCanvas = false) {
  postMessage({
    status: MULTIVIEW_WEBGL_CONTEXT_LOST,
    canvasId,
    replaceCanvas: false,
  });
}

export function create_webgl_context_failed_monitor(canvasId) {
  postMessage({
    status: WEBGL_CONTEXT_CREATE_FAILED,
    canvasId,
  });
}

function getFilename(url) {
  return url.split('/').pop().split('?')[0].split('#')[0];
}

export function str2uint8s(str) {
  if (!str) {
    return null;
  }

  let uint8s = new Uint8Array(str.length);
  for (let i = 0; i < str.length; i++) {
    uint8s[i] = str.charCodeAt(i);
  }

  return uint8s;
}

export function Deferred() {
  let that = this;
  this.promise = new Promise(function (resolve, reject) {
    that.reject = reject;
    that.resolve = resolve;
  });
}

export function get_frequently_2d_context(canvas) {
  let context;
  try {
    context = canvas?.getContext('2d', {
      willReadFrequently: true,
    });
    if (!context) {
      throw new Error(
        `getContext return null  for willReadFrequently, canvas:${canvas}`
      );
    }
  } catch (e) {
    context = canvas?.getContext('2d');
  }
  if (!context) {
    globaltracing_error(`get2DContextFromCanvas return null`);
  }
  return context;
}

export class SharedBufferList {
  constructor(capacity = 10, bytesPerElement = 1500000) {
    this.uint8Map = {};
    this.availableIndex = [];
    this.capacity = capacity;
    this.bytesPerElement = bytesPerElement;
    /**
     * @type {{number: boolean}} , boolean = true, available; false => unavailable
     */
    this.avaiableIndexMap = {};
    this.deferedList = [];
    for (let i = 0; i < capacity; i++) {
      this.uint8Map[i] = new Uint8Array(bytesPerElement);
      this.availableIndex.push(i);
      this.avaiableIndexMap[i] = true;
    }
  }

  /**
   * Each buffer is replaced with a new ArrayBuffer,
   * and since the new ArrayBuffer is larger than the old one,
   * it retains all the data in the old buffer
   * redundant bytes filled with 0
   * @param addByteLen {number}
   */
  increaseBufferSize(addByteLen) {
    let newbytesPerElement = this.bytesPerElement + addByteLen;
    this.bytesPerElement = newbytesPerElement;

    for (let i = 0; i < this.capacity; i++) {
      let oldUint8s = this.uint8Map[i];

      let newUint8s = new Uint8Array(newbytesPerElement);
      newUint8s.set(oldUint8s, 0);
      this.uint8Map[i] = newUint8s;
    }
  }

  getCapacity() {
    return this.capacity;
  }

  /**
   * @returns {Promise<{uint8s: Uint8Array, index: number}>}
   */
  get() {
    if (this.availableIndex.length > 0) {
      let index = this.availableIndex.shift();
      this.avaiableIndexMap[index] = false;
      return new Promise((resolve, reject) => {
        resolve({
          index,
          uint8s: this.uint8Map[index],
        });
      });
    } else {
      let defered = new Deferred();
      this.deferedList.push(defered);
      return defered.promise;
    }
  }

  getSync() {
    if (this.availableIndex.length > 0) {
      let index = this.availableIndex.shift();
      this.avaiableIndexMap[index] = false;
      return {
        index,
        uint8s: this.uint8Map[index],
      };
    } else {
      return null;
    }
  }

  recycle(index) {
    if (this.avaiableIndexMap[index] === true) return;
    this.avaiableIndexMap[index] = true;
    this.availableIndex.push(index);

    if (this.deferedList.length > 0) {
      let deferd = this.deferedList.shift();
      deferd.resolve(this.get());
    }
  }
}

export class YuvWrap {
  /**
   * @param sharedBufferList {SharedBufferList}
   */
  constructor(sharedBufferList) {
    this.sharedBufferList = sharedBufferList;
  }

  /**
   * If the length of the data you want to save is more than {@link sharedBufferList.bytesPerElement},
   * then increaseBufferSize and store the data
   *
   * But if the length of the data is too large, more than maxBytesPerElement
   * return promise.reject
   *
   * @param uint8sSource {Uint8Array}
   * @param maxBytesPerElement {number}
   * @returns {Promise}
   */
  storeFlexible(uint8sSource, maxBytesPerElement) {
    let overflowBytes =
      uint8sSource.byteLength - this.sharedBufferList.bytesPerElement;
    if (overflowBytes > 0) {
      let baseByteLen = Math.floor(this.sharedBufferList.bytesPerElement * 0.1);
      let increaseSize =
        overflowBytes > baseByteLen ? overflowBytes : baseByteLen;

      let increaseAfterSize =
        increaseSize + this.sharedBufferList.bytesPerElement;

      if (increaseAfterSize > maxBytesPerElement) {
        return Promise.reject('too big, more than maxBytesPerElement');
      } else {
        this.sharedBufferList.increaseBufferSize(increaseSize);
      }
    }

    return this.store(uint8sSource);
  }

  store(uint8sSource) {
    return this.sharedBufferList.get().then((obj) => {
      try {
        this.obj = obj;
        // Discovery during integration testing, uint8sSource could be detached, then xx.set(..) will throw an error
        obj.uint8s.set(uint8sSource, 0);
        this.yuvdata = new Uint8Array(
          obj.uint8s.buffer,
          0,
          uint8sSource.byteLength
        );
        return true;
      } catch (e) {
        throw e;
      } finally {
        this.autoRecycle();
      }
    });
  }

  /**
   * @param uint8sSource
   * @returns {boolean} indicate that store operation is successful. if no available buffer in bufferlist, return false,
   */
  storeSync(uint8sSource) {
    let obj = this.sharedBufferList.getSync();
    if (obj !== null) {
      this.obj = obj;
      // Discovery during integration testing, uint8sSource could be detached, then xx.set(..) will throw an error
      obj.uint8s.set(uint8sSource, 0);
      this.yuvdata = new Uint8Array(
        obj.uint8s.buffer,
        0,
        uint8sSource.byteLength
      );
      return true;
    } else {
      return false;
    }
  }

  /**
   *  Make sure all buffer can be recycled,
   *  It is important to ensure there is available buffer.
   *  if some buffer is dropped and never recycled, then the available buffer of {@link sharedBufferList} will be empty finally.
   */
  autoRecycle() {
    this.autoRecycleInterval = setTimeout(() => {
      console.log('autoRecycle', this.obj.index);
      this.recycle();
    }, 5000);
  }

  recycle() {
    try {
      if (this.autoRecycleInterval) clearInterval(this.autoRecycleInterval);
      this.sharedBufferList.recycle(this.obj.index);
    } catch (e) {
      globaltracing_error(`Error in YuvWrap.recycle: ${e}`);
    }
  }
}

function onWebTransportOpen(group, index) {
  if (index >= group.groupSize) {
    return;
  }
  if (group.openStatusArray[index]) {
    console.warn(`group web transport index ${index}, status reopene`);
    return;
  }
  group.openStatusArray[index] = true;
  group.openedCount += 1;
  if (group.openedCount > 1) {
    return;
  }

  group.params.onopen(group);
}

function onWebTransportClose(group, index) {
  if (index >= group.groupSize) {
    return;
  }
  if (!group.openStatusArray[index]) {
    console.warn(`group web transport index ${index}, not open`);
    return;
  }
  group.openStatusArray[index] = false;
  if (group.openedCount > 0) {
    group.openedCount -= 1;
    if (group.openedCount == 0) {
      group.params.onclose(group);
    }
  }
}

// Add your prefix here.
const browserPrefixes = ['', 'MOZ_', 'OP_', 'WEBKIT_'];

export function getExtensionWithKnownPrefixes(gl, name) {
  for (var ii = 0; ii < browserPrefixes.length; ++ii) {
    var prefixedName = browserPrefixes[ii] + name;
    var ext = gl.getExtension(prefixedName);
    if (ext) {
      return ext;
    }
  }
}

export class WebTransportGroup {
  /**
   * @param params.url {string}
   * @param params.label {string}
   * @param params.onmessage {Function}
   * @param params.onopen {Function}
   * @param params.onerror {Function}
   * @param params.onclose {Function}
   * @param params.groupSize {int}
   */
  constructor(params) {
    this.params = params;
    this.label = this.params.label || '';
    this.id = this.params.id || 0;
    this.groupSize = params.groupSize;
    this.openedCount = 0;
    this.sendIndex = 0;
    this.transportArray = [];
    this.openStatusArray = [];
    for (let i = 0; i < this.groupSize; i++) {
      this.transportArray.push(null);
      this.openStatusArray.push(false);
    }
  }

  async connect() {
    for (let i = 0; i < this.groupSize; i++) {
      let transportUrl = this.params.url;
      if (i > 0) {
        transportUrl += '&index=' + i;
      }
      let transportParams = {
        url: transportUrl,
        label: this.params.label,
        id: this.id,
        onmessage: this.params.onmessage,
        onopen: onWebTransportOpen,
        onclose: onWebTransportClose,
        group: this,
        index: i,
      };
      let transport = new WebTransportUtil(transportParams);
      await transport.connect();
      this.transportArray[i] = transport;
    }
  }

  send(uint8s) {
    if (this.openedCount <= 0) {
      return;
    }
    for (let i = 0; i < this.groupSize; i++) {
      this.sendIndex += 1;
      let index = this.sendIndex % this.groupSize;
      if (this.transportArray[index] && this.openStatusArray[index]) {
        this.transportArray[index].send(uint8s);
        break;
      }
    }
  }

  forceClose() {
    for (let i = 0; i < this.groupSize; i++) {
      if (this.transportArray[i]) {
        this.transportArray[i].forceClose();
      }
    }
  }
}

export class WebTransportUtil {
  /**
   * @param params.url {string}
   * @param params.label {string}
   * @param params.onmessage {Function}
   * @param params.onopen {Function}
   * @param params.onerror {Function}
   * @param params.onclose {Function}
   */
  constructor(params) {
    this.params = params;
    this.label = this.params.label || '';
    this.id = this.params.id || 0;
    /**
     * Increase by one after a successful connection
     */
    this.successfulConnectedCount = 0;
    this.connectIndex = 0;
    this.heartbeat = new Uint8Array([104, 101, 97, 114, 116, 98, 101, 97, 116]);
    this.transport = null;
    this.transport_ready = false;
    this.isDestroyed = false;
  }

  async connect() {
    this.reconnect();
  }

  async reconnect() {
    if (this.isReconnectNow || this.isTimerExist || this.isDestroyed) return;
    this.isReconnectNow = true;
    this.isTimerExist = true;
    let seconds = Math.pow(2, this.connectIndex) - 1;
    this.connectIndex += 1;

    setTimeout(async () => {
      if (this.isDestroyed) return;
      this.isTimerExist = false;
      let { url } = this.params;
      let transport = new WebTransport(url);
      this.transport = transport;
      transport.closed
        .then(() => {
          this.transport_ready = false;
          if (this.params.onclose) {
            this.params.onclose(this.params.group, this.params.index);
          }
        })
        .catch((ex) => {
          this.transport_ready = false;
          if (this.params.onerror) {
            this.params.onerror(ex);
          }
          this.params.onclose(this.params.group, this.params.index);
          if (this.connectIndex < 8) {
            this.reconnect();
          }
        });

      try {
        this.isReconnectNow = false;
        await transport.ready;
        if (this.isDestroyed) {
          this.close();
          return;
        }

        this.transport_ready = true;
        this.successfulConnectedCount++;

        transport.datagrams.incomingMaxAge = 1000;
        transport.datagrams.outgoingMaxAge = 100;
        transport.datagrams.incomingHighWaterMark = 800;
        transport.datagrams.outgoingHighWaterMark = 800;

        this.writer = transport.datagrams.writable.getWriter();
        this.reader = transport.datagrams.readable.getReader();
        await this.writer.ready;
        this.params.onopen(this.params.group, this.params.index);
      } catch (err) {
        this.params.onerror && this.params.onerror(err);
        this.close();
        return;
      }

      this.startHeartbeat();
      this.read();
    }, seconds * 1000);
  }

  send(uint8s) {
    this.writer.write(uint8s);
  }

  async sendAwaitReady(uint8s) {
    await this.writer.ready;
    await this.writer.write(uint8s);
  }

  async startHeartbeat() {
    while (true) {
      await this.sleep(3000);
      await this.sendAwaitReady(this.heartbeat);
    }
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  localTime() {
    let currentdate = new Date();
    let datetime =
      'local time : ' +
      currentdate.getDate() +
      '/' +
      (currentdate.getMonth() + 1) +
      '/' +
      currentdate.getFullYear() +
      ' @ ' +
      currentdate.getHours() +
      ':' +
      currentdate.getMinutes() +
      ':' +
      currentdate.getSeconds() +
      ' ';
    return datetime;
  }

  close() {
    try {
      this.transport_ready = false;
      if (this.transport) {
        this.transport.close();
      }
    } catch (e) {}
  }

  /**
   * @description force close webtransport and should not auto reconnect
   */
  forceClose() {
    if (this.isDestroyed) return;
    this.isDestroyed = true;
    this.close();
  }

  async read() {
    let reader = this.reader;
    while (true) {
      const { value, done } = await reader.read();
      if (done) {
        break;
      }

      this.params.onmessage(value);
    }
  }
}

class VideoFPSMGR {
  constructor() {
    this.ssrcInfoMap = new Map();
    this.timer = null;
  }
  updateSSRCInfo(ssrc, time) {
    if (!this.ssrcInfoMap.has(ssrc)) {
      this.ssrcInfoMap.set(ssrc, {
        firstTime: 0,
        lastTime: 0,
        frames: 0,
        fps: 0,
      });
    }
    this._calculateFPS(ssrc, time);
    this._removeZeroFPS();
    // this._checkIfNewFrameComing();
  }

  _calculateFPS(ssrc, time) {
    const info = this.ssrcInfoMap.get(ssrc);
    if (info.frames === 0) {
      info.firstTime = time;
    } else {
      info.lastTime = time;
    }
    info.frames += 1;
    if (info.frames > 2 && info.frames % 5 == 0) {
      if (info.lastTime - info.firstTime >= 1000) {
        const fps = Math.floor(
          1000 / ((info.lastTime - info.firstTime) / (info.frames - 1))
        );
        if (info.fps !== fps) {
          this._notifyFPS(ssrc, fps);
          info.fps = fps;
        }
        info.firstTime = info.lastTime;
        info.frames = 1;
      }
    }
  }

  _removeZeroFPS() {
    let currentTick = Date.now();
    this.ssrcInfoMap.forEach((_, ssrc) => {
      const info = this.ssrcInfoMap.get(ssrc);
      if (info && currentTick - info.lastTime > 2000) {
        this.ssrcInfoMap.delete(ssrc);
        this._notifyFPS(ssrc, 0);
      }
    });
  }

  _notifyFPS(ssrc, fps) {
    postMessage({
      status: CURRENT_DECODE_VIDEO_FPS,
      data: {
        ssrc,
        fps,
      },
    });
  }

  _checkIfNewFrameComing() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = setTimeout(() => {
      this._removeZeroFPS();
      this.timer = null;
    }, 2500);
  }
}

const qualityMap = new Map();
const qualityList = [90, 180, 360, 720, 1080];
const videoFPSMGR = new VideoFPSMGR();
/** expose the decode video quality */
export function observeVideoQuality(
  data,
  enablefps = false,
  notifyCallback = null
) {
  const { r_w, r_h, rotation, ssrc: originSsrc } = data;
  let rote = rotation == 1 || rotation == 3;
  let width = rote ? r_h : r_w;
  let height = rote ? r_w : r_h;

  const ssrc = (originSsrc >> 10) << 10;
  const exactHeight = qualityList.reduce((prev, now) => {
    if (Math.abs(prev - height) > Math.abs(now - height)) {
      return now;
    }
    return prev;
  }, qualityList[0]);
  const quality = qualityList.findIndex((item) => item === exactHeight);
  if (
    !qualityMap.get(ssrc) ||
    qualityMap.get(ssrc).width !== width ||
    qualityMap.get(ssrc).height !== height
  ) {
    const ssrcQuality = {
      width,
      height,
      ssrc,
      quality,
    };
    qualityMap.set(ssrc, ssrcQuality);
    if (notifyCallback) {
      notifyCallback(ssrcQuality);
    } else {
      postMessage({
        status: CURRENT_DECODE_VIDEO_QUALITY,
        data: ssrcQuality,
      });
    }
  }
  if (enablefps) {
    videoFPSMGR.updateSSRCInfo(ssrc, Date.now());
  }
}
