import { Deferred } from '../common/util';
import Log from '../common/log';

const log = Log('sdk.init');

class MediaInit {
  constructor(name = '') {
    this.name = name;

    this.handler = null;
    this.wasm = null;
    this.socket = null;

    this.defered = new Deferred();
    this.initSuccessPromise = this.defered.promise;

    this.wasmDefered = new Deferred();
    this.wasmSuccessPromise = this.wasmDefered.promise;

    this.handlerDefered = new Deferred();
    this.handlerSuccessPromise = this.handlerDefered.promise;

    this.socketDefered = new Deferred();
    this.socketSuccessPromise = this.socketDefered.promise;

    Promise.all([
      this.wasmSuccessPromise,
      this.handlerSuccessPromise,
      this.socketSuccessPromise,
    ]).then((booleanValues) => {
      let bAllSuccess = booleanValues.every((bool) => bool === true);
      this.defered.resolve(bAllSuccess);

      // for debug usage
      if (!bAllSuccess) {
        log.error(this.name, {
          handler: this.handler,
          wasm: this.wasm,
          socket: this.socket,
        });
      }
    });
  }

  setHanderSuccess(bSuccess = true) {
    this.handler = bSuccess;
    this.handlerDefered.resolve(bSuccess);
  }

  setWasmSuccess(bSuccess = true) {
    this.wasm = bSuccess;
    this.wasmDefered.resolve(bSuccess);
  }

  setSocketSuccess(bSuccess = true) {
    this.socket = bSuccess;
    this.socketDefered.resolve(bSuccess);
  }

  /**
   * @returns {null|boolean}
   */
  checkInitSuccess() {
    let bSuccess = this.handler && this.wasm && this.socket;
    if (bSuccess) {
      this.defered.resolve(true);
    }
    return bSuccess;
  }

  isSocketInitSuccess() {
    return this.socket;
  }

  /**
   * @returns {Promise}
   */
  waitforInitSuccess() {
    return this.initSuccessPromise;
  }
  /**
   * current only used for video share reinit
   */
  resetHandleDeferred() {
    this.setHanderSuccess(true);
    this.handlerDefered = new Deferred();
    this.handlerSuccessPromise = this.handlerDefered.promise;
  }
}

export class SharingInit extends MediaInit {
  constructor(name) {
    super(name);
  }
}

export class VideoInit extends MediaInit {
  constructor(name) {
    super(name);
  }
}

export class AudioInit extends MediaInit {
  constructor(name) {
    super(name);
  }
}
