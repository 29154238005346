import GPUTexturePool from './GPUTexturePool';
import {
  GPU_RES_TYPE,
  RES_OCCUPANCY_LEVEL,
  TEXTURE_MIP_LEVELS,
} from './RenderConst';

import { add_monitor } from '../../worker/common/common';

/**
 * GPUTextureManager is used to manage GPUTextures created by the GPU.
 *
 * Any operation of handling GPU resources is expensive for GPU, so it
 * is a good practice to reuse these resources. Like calling destroy() or
 * createTexture() will cost a lot within a high frequency.
 */
class GPUTextureManager {
  #mDevice = null;
  #mResInfo = {};
  #mTexturesMap = new Map();
  #mSpecialTexturesMap = new Map();

  constructor(device) {
    this.#mDevice = device;
  }

  /**
   * Assemble a texture configuration as the key of the map.
   *
   * @param {*} w width of texture
   * @param {*} h height of texture
   * @param {*} usage usage of texture
   * @param {*} format format of texture
   * @param {*} sampleCount sampleCount of texture, if enable msaa, need 4, if not, need 1
   * @returns a key of the map
   */
  assembleTextureConfig(w, h, usage, format, sampleCount) {
    const texMipLevel = this.#getTextureMipLevel(h);
    const texConfig = {
      w: w,
      h: h,
      usage: usage,
      format: format,
      sampleCount: sampleCount,
      level: texMipLevel,
    };

    return texConfig;
  }

  /**
   * Check whether the two keys have same values of all attributes.
   *
   * @param {*} cachedKey key in the map
   * @param {*} newKey key to be compared
   * @returns if true, all the values are same, false otherwise
   */
  isSameTextureConfig(cachedKey, newKey) {
    if (!cachedKey || !newKey) {
      return false;
    }

    // compare
    if (
      cachedKey.w != newKey.w ||
      cachedKey.h != newKey.h ||
      cachedKey.usage != newKey.usage ||
      cachedKey.format != newKey.format ||
      cachedKey.sampleCount != newKey.sampleCount
    ) {
      return false;
    }

    return true;
  }

  /**
   * Evaluate a texture configuration as the key.
   *
   * @param {*} texConfig target texture configuration
   * @returns a new key with texConfig or an existing key in the map
   */
  evalTexConfig(texConfig) {
    if (!texConfig) {
      throw new Error('hasSameTextureConfig() invalid input!');
    }

    // const isKeyFound = [...keysItr].some(key => this.isSameTextureConfig(key, texConfig));
    const keysItr = this.#mTexturesMap.keys();
    let texConfigAsKey = texConfig;
    for (const key of keysItr) {
      if (this.isSameTextureConfig(key, texConfig)) {
        texConfigAsKey = key;
        break;
      }
    }

    return texConfigAsKey;
  }

  // unused
  needNewTexturesMapEntry(cachedKey, newKey) {
    if (!newKey) {
      return false;
    }

    if (!cachedKey) {
      return true;
    }

    // compare
    if (
      cachedKey.w != newKey.w ||
      cachedKey.h != newKey.h ||
      cachedKey.usage != newKey.usage ||
      cachedKey.format != newKey.format ||
      cachedKey.sampleCount != newKey.sampleCount
    ) {
      return true;
    }

    return false;
  }

  /**
   * Acquire a GPUTexture.
   * Note, you can try to acquire an available GPUTexture though, it is still
   * possible to return null due to the invalid texture configuration or uninitialized
   * GPUDevice, etc.
   *
   * @param {*} texConfig a texture configuration
   * @returns an available GPUTexture or null
   */
  acquireTexture(texConfig) {
    if (!texConfig || texConfig.w <= 0 || texConfig.h <= 0) {
      console.error(`[GPUTextureMgr] acquireTexture() texConfig is invalid`);
      return null;
    }

    let texture = null;
    if (this.#isSpecialTextureConfig(texConfig)) {
      texture = this.#acquireSpecialTexture(texConfig);
    } else {
      texture = this.#acquireNormalTexture(texConfig);
    }

    return texture;
  }

  #acquireNormalTexture(texConfig) {
    let texPool = this.#mTexturesMap.get(texConfig.level);
    let availableTex = null;
    if (!texPool) {
      // 1. create a gpu texture first
      availableTex = this.#createGPUTexture(texConfig);

      // 2. create a new texture pool and push an available tex to it
      if (availableTex) {
        texPool = new GPUTexturePool();
        texPool.pushToInUsePool(availableTex);
        this.#mTexturesMap.set(texConfig.level, texPool);
      } else {
        console.error(
          `acquireTexture() cannot create an available tex. texConfig=${JSON.stringify(
            texConfig
          )}`
        );

        add_monitor(
          `[GPUTextureMgr] acquireTexture() failed! texConfig:${JSON.stringify(
            texConfig
          )}`
        );
      }
    } else {
      // if has, here are 3 cases:
      // 1. level is found but the texPool is null
      // 2. level is found and the texPool is not null, but no available texture
      // 3. level is found and the texPool is not null, has an available texture
      availableTex = texPool.acquire(texConfig);
      if (!availableTex) {
        availableTex = this.#createGPUTexture(texConfig);
        if (availableTex) {
          texPool.pushToInUsePool(availableTex);
        } else {
          console.error(
            `acquireTexture() cannot create an available tex. texConfig=${JSON.stringify(
              texConfig
            )}`
          );

          add_monitor(
            `[GPUTextureMgr] acquireTexture() failed! texConfig:${JSON.stringify(
              texConfig
            )}`
          );
        }
      }
    }

    return availableTex;
  }

  #acquireSpecialTexture(texConfig) {
    const zOrder = texConfig.zOrder;
    let specialTexture = this.#mSpecialTexturesMap.get(zOrder);
    if (!specialTexture) {
      specialTexture = this.#createGPUTexture(texConfig);
      this.#mSpecialTexturesMap.set(zOrder, specialTexture);
    } else {
      // if has this special texture, we need to check whether reuse this texture or not
      // if the texture size is larger than the size required in the texture config,
      // to use the cached special texture, that is, a high-resolution texture will be sampled to
      // a smaller color attachment
      // if the texture size is smaller the size required in the texture config, a new texture
      // will be created util the required size is bigger enough than some levels
      if (
        texConfig.w > specialTexture.width ||
        texConfig.h > specialTexture.height
      ) {
        specialTexture.destroy();
        specialTexture = this.#createGPUTexture(texConfig);
        this.#mSpecialTexturesMap.set(zOrder, specialTexture);
      }
    }

    return specialTexture;
  }

  /**
   * Recycle a GPUTexture and make it available again.
   *
   * @param {*} texture a GPUTexture which is recycled
   * @param {boolean} [destroy=false] if true, the texture should be destroyed, otherwise, recycle it
   */
  recycleTexture(texture, destroy = false) {
    if (!texture) {
      return;
    }

    const texConfig = this.assembleTextureConfig(
      texture.width,
      texture.height,
      texture.usage,
      texture.format,
      texture.sampleCount
    );

    let texPool = this.#mTexturesMap.get(texConfig.level);
    if (texPool) {
      texPool.recycle(texture, destroy);
    } else {
      // if not found, means the texture is separated from the texture map
      // try to add it to the map here
      console.warn(
        `recycleTexture(${texture.label}) texture is not found in the map!, destroy:${destroy}`
      );

      if (destroy) {
        texture.destroy();
      } else {
        const texPool = new GPUTexturePool();
        texPool.pushToAvailablePool(texture);
        this.#mTexturesMap.set(texConfig.level, texPool);
      }
    }
  }

  /**
   * Create a new GPUTexture.
   *
   * @param {*} texConfig a texture configuration
   * @returns a new GPUTexture or null
   */
  #createGPUTexture(texConfig) {
    if (!this.#mDevice) {
      return null;
    }

    if (texConfig.w == 0 || texConfig.h == 0) {
      return null;
    }

    const desc = {
      size: { width: texConfig.w, height: texConfig.h },
      format: texConfig.format,
      usage: texConfig.usage,
    };

    if (texConfig.sampleCount > 0) {
      desc.sampleCount = texConfig.sampleCount;
    }

    return this.#mDevice.createTexture(desc);
  }

  /**
   * Cleanup all the allocated GPUTextures that saved in the GPUTexturePool.
   */
  cleanup() {
    for (const [key, val] of this.#mTexturesMap) {
      if (val) {
        val.cleanup();
      }
    }

    this.#mTexturesMap.clear();
  }

  /**
   * Collect the resource information from GPUTextureManager component.
   * @returns an object that holds resource information
   */
  collectResourceInfo() {
    let count = 0;
    let usedBytes = 0;
    let log = '';
    for (const [key, val] of this.#mTexturesMap) {
      if (val) {
        const availablePool = val.getAvailablePool();
        for (const tex of availablePool) {
          count++;
          if (tex.format == 'r8unorm') {
            usedBytes += tex.width * tex.height;
          } else if (tex.format == 'rgba8unorm') {
            usedBytes += tex.width * tex.height * Uint32Array.BYTES_PER_ELEMENT;
          }
        }

        const inUsedPool = val.getInUsedPool();
        for (const tex of inUsedPool) {
          count++;
          if (tex.format == 'r8unorm') {
            usedBytes += tex.width * tex.height;
          } else if (tex.format == 'rgba8unorm') {
            usedBytes += tex.width * tex.height * Uint32Array.BYTES_PER_ELEMENT;
          }
        }

        if (availablePool.length > 0 || inUsedPool.length > 0) {
          log += `[GPUTexturePool] level:${key} pool:{ava_count:${availablePool.length} in_used_count:${inUsedPool.length}}\n`;
        }
      }
    }

    log += `[GPUTexturePool] total: count:${count} usedBytes:${usedBytes}\n`;

    this.#mResInfo.type = GPU_RES_TYPE.TEXTURE;
    this.#mResInfo.count = count;
    this.#mResInfo.usedBytes = usedBytes;
    this.#mResInfo.output = log;

    return this.#mResInfo;
  }

  onOccupancyLevelEvaluated(level) {
    console.log(
      `[GPUTextureManager] onOccupancyLevelEvaluated() level:${level}`
    );

    add_monitor(
      `[GPUTextureManager] onOccupancyLevelEvaluated() level:${level}`
    );

    if (level == RES_OCCUPANCY_LEVEL.OVERUSE) {
      for (const [key, val] of this.#mTexturesMap) {
        if (val) {
          val.release(level);
        }
      }
    }
  }

  #getTextureMipLevel(height) {
    let texMipLevel = TEXTURE_MIP_LEVELS[TEXTURE_MIP_LEVELS.length - 1];
    for (let i = 0; i < TEXTURE_MIP_LEVELS.length; ++i) {
      if (height <= TEXTURE_MIP_LEVELS[i]) {
        texMipLevel = TEXTURE_MIP_LEVELS[i];
        break;
      }
    }

    return texMipLevel;
  }

  #isSpecialTextureConfig(textureConfig) {
    return (
      textureConfig &&
      textureConfig.zOrder &&
      textureConfig.zOrder == TEX_LAYER_Z_IDX.WATERMARK
    );
  }

  // #acquireSpecialTexture(textureConfig) {
  //   const zOrder = textureConfig.zOrder;
  //   let specialTexture = this.#mSpecialTexturesMap.get(zOrder);
  //   if (!specialTexture) {
  //     specialTexture = this.#createGPUTexture(textureConfig);
  //     this.#mSpecialTexturesMap.set(zOrder, specialTexture);
  //   } else {
  //     // if has this special texture, we need to check whether reuse this texture or not
  //     // if the texture size is larger than the size required in the texture config,
  //     // to use the cached special texture, that is, a high-resolution texture will be sampled to
  //     // a smaller color attachment
  //     // if the texture size is smaller the size required in the texture config, a new texture
  //     // will be created util the required size is bigger enough than some levels
  //     // if ()
  //     // const texMipLevel = this.#getTextureMipLevel(h);
  //   }

  //   return specialTexture;
  // }
}

export default GPUTextureManager;
