import * as jsEvent from '../common/jsEvent';
import * as consts from '../worker/common/consts';
import * as RenderConst from '../common/renderer/RenderConst';
import JsMediaSDK_WaterMarkRGBA from './JsMediaSDK_WaterMark';
import { VideoQueueMGR } from '../inside/JsMediaBuffer';
import {
  YuvWrap,
  multiview_webgl_monitor,
  globaltracing_error,
  GET_HEAP_U8,
} from '../worker/common/common';

/**
 * @description get target ratio cropping params
 * @param {*} drawRatio cropping ratio (16:9 default)
 */
export const get16V9CroppingParams = (
  width,
  height,
  left = 0,
  top = 0,
  drawRatio = 16 / 9
) => {
  if (!width || !height) {
    return null;
  }
  const originLeft = left || 0;
  const originTop = top || 0;
  if (width / height > drawRatio) {
    /** data is widther than render area */
    const realDrawDataWidth = height * drawRatio;
    return {
      width: realDrawDataWidth,
      height: height,
      left: Math.round((width - realDrawDataWidth) / 2) + originLeft,
      top: 0 + originTop,
    };
  } else {
    /** data is heighter than render area */
    const realDrawDataHeight = width / drawRatio;
    return {
      width: width,
      height: realDrawDataHeight,
      left: 0 + originLeft,
      top: Math.round((height - realDrawDataHeight) / 2) + originTop,
    };
  }
};

function VideoRender(params) {
  this.Notify_APPUI = params.Notify_APPUI;
  this.isSupportOffscreenCanvas = params.isSupportOffscreenCanvas;
  this.jsMediaEngine = params.jsMediaEngine;
  this.threadNumbs = params.videodecodethreadnumb;
  this.globalTracingLogger = params.globalTracingLogger;
  this.monitorLoggerFn = params.monitorLoggerFn;
  this.renderManager = params.renderManager;

  this.waterMarkCanvas = null;
  this.videoRenderLevel = 10;
  this.videoRenderLevelCount = 1;
  this.renderPeriodTotal = 100;
  this.renderPeriodCount = 0;
  this.renderPeriodTotalFps = 0;
  this.renderPeriodFpsCount = 0;
  this.lastRenderAudioTimeStamp = 0;
  this.videoBufEmptyCount = 0;
  this.videoBufCount = 0;
  this.videoRenderMaxLevel = 15;
  this.timestamp = 0;
  this.audioPlayTime = 0;
  this.videoTimestamp = 0;
  this.videoWaterMarkName = '';
  this.isCreateVideoWaterMark = false;
  /** if makes watermark repeated */
  this.isWaterMarkRepeatedEnable = false;
  this.waterMarkOpacity = 0.15;
  this.currentactive = 0;
  this.audioPlaySsrc = 0;
  this.currentVideoHeight = 0;
  this.currentVideoWidth = 0;
  this.videoRenderArray = [];
  this.WaterMarkRGBA = new JsMediaSDK_WaterMarkRGBA();
  this.vMonitorCount = 0;
  this.videoQueue = new VideoQueueMGR();
  this.timestart = 0;
  this.rendingFlag = true;
  this.rAFID = 0;
  this.rAFIDBack = null;
  this.prevRequestAnimationTimestap = 0;
  this.retryRequestAnimationStep = 2;
  this.ssrcDisplayMap = new Map();
  this.ssrcHaddataMap = new Map();
  this.canvasToLocalDisplay = new Map();
  this.canvasIdSet = new Set();
  // this.reuseDisplayMap = new Map();
  this.selfvideossrc = 0;
  this.localVideoPtr = 0;
  this.sabBuffer = 0;
  this.localvideossrc = 0;
  this.encodedVideoSharedArrayBufferUint8Array = null;
  this.encodedVideoSharedArrayBufferUint16Array = null;
  this.encodedVideoSharedArrayWasmMemory = null;
  this.startCaptureVideo = false;
  this.clearColor = new Uint8Array(4);
  this.videorendercanvas = null;
  this.keyrender = null;
  this.renderIndex = 0;
  this.activeSpeakssrc = 0;
  this.needClear = false;

  /**
   * 0 => requestAnimation mode
   * 1 => setInterval mode
   */
  this.renderMode = 0;
  this.intervalHandle;
  this.croppingParams = {
    top: 0,
    left: 0,
    height: 1,
    width: 1,
  };
  this.coordinate = {
    x: 0,
    y: 0,
    width: 640,
    height: 360,
  };
  this.isSupportVideoTrackReader = false;
  this.bWebglContextLostProtectingMap = new Map();

  this.ismirror = false;

  this.lfTimeStamp = 0;
  this.activeFps = 0;
  this.lastActiveSSRC = 0;

  if (process.env.NODE_ENV === 'development') {
    let globalScope = typeof window === 'undefined' ? self : window;
    globalScope.videoRenderOBJ = this;
  }

  /** disable originalRatio or not */
  this.disableOriginalRatio = false;

  this.onStopDrawCallback = params.onStopDrawCallback;
  this.onReStoreCallback = params.onReStoreCallback;
  this.videoRenderWGLFSTOP = false;
}

VideoRender.prototype.clearUseridRender = function (data, RGBA, originalPos) {
  let obseleteData = data[0];
  let display = obseleteData.display;
  if (!display) {
    return;
  }

  display.setWatermarkFlag(0);

  if (this.renderMode) {
    display.clearCanvas(RGBA);
  } else {
    let obseletessrc = obseleteData.ssrc;
    if (!display || !RGBA) {
      return;
    }

    this.croppingParams.top = 0;
    this.croppingParams.left = 0;
    this.croppingParams.height = 1;
    this.croppingParams.width = 1;
    this.clearColor[0] = RGBA.R * 255;
    this.clearColor[1] = RGBA.G * 255;
    this.clearColor[2] = RGBA.B * 255;
    this.clearColor[3] = RGBA.A * 255;
    const isWebglRenderer = this.renderManager
      .getRendererProvider()
      .isWebGLRendererType();

    const isWebgl2Renderer = this.renderManager
      .getRendererProvider()
      .isWebGL2RendererType();

    if (isWebglRenderer || isWebgl2Renderer) {
      let clearDisplay = this.canvasToLocalDisplay.get(obseleteData.canvas);
      if (clearDisplay) {
        clearDisplay.setVideoMode(consts.VIDEO_RGBA);
        clearDisplay.colorRange = 0;
        clearDisplay.setFillMode(1, 1);
        clearDisplay.updateSelfVideoTextures(
          1,
          1,
          this.clearColor,
          this.croppingParams,
          true
        );
      } else {
        console.log('no clearDisplay');
        return;
      }
      if (originalPos === undefined) {
        originalPos = {};
        originalPos.x = obseleteData.x;
        originalPos.y = obseleteData.y;
        originalPos.width = obseleteData.width;
        originalPos.height = obseleteData.height;
      }
      clearDisplay.drawSelfVideo(originalPos, true);
    } else {
      if (display) {
        display.setVideoMode(consts.VIDEO_RGBA);
        display.setColorRange(0);
        display.setFillMode(1, 1);
        display.updateSelfVideoTextures(
          1,
          1,
          this.clearColor,
          this.croppingParams,
          true
        );
      } else {
        console.log('no clearDisplay');
        return;
      }

      if (originalPos === undefined) {
        originalPos = {};
        originalPos.x = obseleteData.x;
        originalPos.y = obseleteData.y;
        originalPos.width = obseleteData.width;
        originalPos.height = obseleteData.height;
      }

      display.drawSelfVideo(originalPos, true);
    }

    let that = this;
    this.ssrcDisplayMap.forEach(function (value, key) {
      let isSelfVideo;
      if (key == that.Get_Logical_SSrc(that.selfvideossrc)) {
        isSelfVideo = true;
      }

      let isClearVideo;
      if (key == that.Get_Logical_SSrc(obseletessrc)) {
        isClearVideo = true;
      }
      value.forEach((frameWrapper) => {
        if (!frameWrapper.haddata || isClearVideo) {
          return;
        }
        let display = frameWrapper.display;
        that.coordinate.x = frameWrapper.x;
        that.coordinate.y = frameWrapper.y;
        that.coordinate.width = frameWrapper.width;
        that.coordinate.height = frameWrapper.height;

        if (display) {
          if (isClearVideo && (!isWebglRenderer || !isWebgl2Renderer)) {
            display.drawSelfVideo(that.coordinate, true);
          } else {
            if (display.getVideoMode() != consts.VIDEO_INVALID) {
              if (
                [consts.VIDEO_RGBA, consts.VIDEO_BGRA].indexOf(
                  display.getVideoMode()
                ) == -1 &&
                isSelfVideo
              ) {
                display.drawSelfVideo(that.coordinate, false, that.ismirror);
              } else {
                if (isSelfVideo) {
                  display.drawRemoteVideo(that.coordinate, that.ismirror);
                } else {
                  display.drawRemoteVideo(that.coordinate);
                }
              }
            }
          }
        }
      });
    });

    this.renderManager.renderFor(RenderConst.SERVE_FOR.VIDEO);
  }
};

VideoRender.prototype.Set_Render_Array_rAF = function (videoRenderArray) {
  //We will cut down cost Next Phase
  //@(seth.wang)
  this.videoRenderArray = videoRenderArray;
  this.ssrcDisplayMap.clear();
  this.ssrcHaddataMap.clear();
  if (this.videoRenderArray.length > 0) {
    for (let i = 0; i < this.videoRenderArray.length; i++) {
      let displayarray = this.ssrcDisplayMap.get(
        this.Get_Logical_SSrc(this.videoRenderArray[i].ssrc)
      );
      if (displayarray) {
        displayarray.push(this.videoRenderArray[i]);
      } else {
        displayarray = [];
        displayarray.push(this.videoRenderArray[i]);
        this.ssrcDisplayMap.set(
          this.Get_Logical_SSrc(this.videoRenderArray[i].ssrc),
          displayarray
        );
        this.ssrcHaddataMap.set(
          this.Get_Logical_SSrc(this.videoRenderArray[i].ssrc),
          false
        );
      }
    }
  }
};

VideoRender.prototype.Set_Render_Array = function (
  videoRenderArray,
  enableMultiDecodeVideoWithoutSAB
) {
  if (this.renderMode) {
    this.Set_Render_Array_IntervalMode(
      videoRenderArray,
      enableMultiDecodeVideoWithoutSAB
    );
  } else {
    this.Set_Render_Array_rAF(videoRenderArray);
  }
};

VideoRender.prototype.ClearQueue = function () {
  if (this.videoQueue) {
    this.videoQueue.ClearQueue();
  }
  this.currentVideoHeight = 0;
  this.currentVideoWidth = 0;
};

VideoRender.prototype.Put_Video_Into_Buffer = function (message) {
  if (this.videoQueue) {
    var ssrcqueue_ = this.videoQueue.GetQueue(message.ssrc);
    if (!ssrcqueue_) {
      ssrcqueue_ = this.videoQueue.AddQueue(message.ssrc);
    }
    ssrcqueue_.enqueue(message);
    if (this.videoQueue && this.videoQueue.ssrcInfo) {
      var ssrc_node_part = message.ssrc >> 10;
      this.videoQueue.ssrcInfo.UpdateSSRCInfo(ssrc_node_part, message.ntptime);
    }
    var videoqueuelength = this.videoQueue.GetQueueLength(message.ssrc);
    var diff = videoqueuelength - 10;
    while (diff >= 0) {
      diff--;
    }
  }
};

VideoRender.prototype.Update_RemoteVideo_Texture = function (
  message,
  newmode = false,
  remotessrc = 0,
  rotation = 0
) {
  this.Check_Request_Animation();
  if (!newmode) {
    if (message && message.ssrc) {
      let ssrc = this.Get_Logical_SSrc(message.ssrc);
      let ssrcRenderArray = this.FindVideoRenderListFromMap(message.ssrc);
      if (!ssrcRenderArray.length) {
        if (message.yuvdataptr) {
          Module._free(message.yuvdataptr);
        }
        this.renderManager.destroyUnusedVideoFrame(message);
        return;
      }

      let renderFrame;
      let isolddata = false;
      let that = this;
      this.ssrcHaddataMap.set(ssrc, true);
      let video_buf = message;

      this.croppingParams.top = video_buf.r_y;
      this.croppingParams.left = video_buf.r_x;
      this.croppingParams.height = video_buf.r_h;
      this.croppingParams.width = video_buf.r_w;

      renderFrame = video_buf;
      if (renderFrame.enableMultiDecodeVideoWithoutSAB) {
        renderFrame.yuvdata = renderFrame.data;
      } else {
        renderFrame.yuvdata = GET_HEAP_U8().subarray(
          renderFrame.yuvdataptr,
          renderFrame.yuvdataptr + renderFrame.yuvlength
        );
      }
      //end video frame
      if (
        !(
          !renderFrame.yuvdata.length ||
          renderFrame.yuvdata.length !=
            (renderFrame.width * renderFrame.height * 3) / 2 ||
          this.croppingParams.top < 0 ||
          this.croppingParams.left < 0 ||
          this.croppingParams.left + this.croppingParams.width >
            renderFrame.width ||
          this.croppingParams.top + this.croppingParams.height >
            renderFrame.height
        )
      ) {
        if (ssrcRenderArray) {
          this.canvasIdSet.clear();
          ssrcRenderArray.forEach((frameWrapper) => {
            frameWrapper.haddata = true;
            let display = frameWrapper.display;
            that.coordinate.x = frameWrapper.x;
            that.coordinate.y = frameWrapper.y;
            that.coordinate.width = frameWrapper.width;
            that.coordinate.height = frameWrapper.height;

            let updateFlag =
              !display ||
              !display.reuse ||
              !frameWrapper.rendercanvasID ||
              !this.canvasIdSet.has(frameWrapper.rendercanvasID);
            if (display) {
              display.setVideoMode(consts.VIDEO_I420);
              if (
                this.Should_Update_Watermark(
                  display,
                  frameWrapper.width,
                  frameWrapper.height,
                  frameWrapper.width,
                  frameWrapper.height
                )
              ) {
                this.Update_Display_Watermark(
                  display,
                  frameWrapper.width,
                  frameWrapper.height,
                  frameWrapper.width,
                  frameWrapper.height
                );
              }
              if (renderFrame) {
                display.updateRemoteVideoTextures(
                  renderFrame.width,
                  renderFrame.height,
                  that.croppingParams,
                  renderFrame.yuvdata,
                  renderFrame.rotation,
                  renderFrame.yuv_limited,
                  that.coordinate,
                  isolddata,
                  updateFlag
                );
              }
              this.canvasIdSet.add(frameWrapper.rendercanvasID);
            }
          });
        }
      }
    } else {
      this.renderManager.destroyUnusedVideoFrame(message);
    }

    if (message.yuvdataptr) {
      Module._free(message.yuvdataptr);
    }
  } else {
    //start
    if (message && remotessrc) {
      let ssrc = this.Get_Logical_SSrc(remotessrc);
      let ssrcRenderArray = this.FindVideoRenderListFromMap(remotessrc);
      if (!ssrcRenderArray.length) {
        this.renderManager.destroyUnusedVideoFrame(message);
        return;
      }
      let that = this;

      this.ssrcHaddataMap.set(ssrc, true);

      this.croppingParams.top = message.visibleRect ? 0 : message.cropTop;
      this.croppingParams.left = message.visibleRect ? 0 : message.cropLeft;
      this.croppingParams.height = message.visibleRect
        ? message.visibleRect.height
        : message.cropHeight;
      this.croppingParams.width = message.visibleRect
        ? message.visibleRect.width
        : message.cropWidth;

      if (true) {
        this.canvasIdSet.clear();
        if (ssrcRenderArray) {
          ssrcRenderArray.forEach((frameWrapper) => {
            frameWrapper.haddata = true;
            let display = frameWrapper.display;

            that.coordinate.x = frameWrapper.x;
            that.coordinate.y = frameWrapper.y;
            that.coordinate.width = frameWrapper.width;
            that.coordinate.height = frameWrapper.height;

            let updateFlag =
              !display ||
              !display.reuse ||
              !frameWrapper.rendercanvasID ||
              !this.canvasIdSet.has(frameWrapper.rendercanvasID);
            if (display) {
              display.setVideoMode(consts.VIDEO_RGBA);
              if (
                that.Should_Update_Watermark(
                  display,
                  frameWrapper.width,
                  frameWrapper.height,
                  frameWrapper.width,
                  frameWrapper.height
                )
              ) {
                that.Update_Display_Watermark(
                  display,
                  frameWrapper.width,
                  frameWrapper.height,
                  frameWrapper.width,
                  frameWrapper.height
                );
              }
              if (message) {
                display.updateRemoteVideoTexturesImageBitmap(
                  that.croppingParams.width,
                  that.croppingParams.height,
                  message,
                  that.croppingParams,
                  rotation,
                  updateFlag
                );
              }
              this.canvasIdSet.add(frameWrapper.rendercanvasID);
            }
          });
        }
      }
    } else {
      this.renderManager.destroyUnusedVideoFrame(message);
    }
    //end
  }
};

/** set croppong mode use original ratio or not */
VideoRender.prototype.Set_Cropping_Mode = function (
  disableOriginalRatio = false
) {
  this.disableOriginalRatio = !!disableOriginalRatio;
};

/** if not use original ratio, need to reset cropping params */
VideoRender.prototype.Update_Cropping_Params = function () {
  if (this.disableOriginalRatio) {
    const croppingParams = get16V9CroppingParams(
      this.croppingParams.width,
      this.croppingParams.height,
      this.croppingParams.left,
      this.croppingParams.top
    );
    if (croppingParams) {
      this.croppingParams = {
        ...this.croppingParams,
        ...croppingParams,
      };
    }
  }
};

VideoRender.prototype.Update_SelfVideo_Texture = function (
  width,
  height,
  data,
  ssrc,
  mode = consts.VIDEO_RGBA,
  cropping = 0
) {
  if (!cropping) {
    this.croppingParams.top = 0;
    this.croppingParams.left = 0;
    this.croppingParams.height = height;
    this.croppingParams.width = width;
  } else {
    this.croppingParams.top = cropping.top;
    this.croppingParams.left = cropping.left;
    this.croppingParams.width = cropping.width;
    this.croppingParams.height = cropping.height;
  }

  if (this.renderMode) {
    let index = 0;
    this.canvasIdSet.clear();
    const displayList = this.FindVideoRenderList(ssrc);
    for (; index < displayList.length; index++) {
      this.coordinate.x = displayList[index].x;
      this.coordinate.y = displayList[index].y;
      this.coordinate.width = displayList[index].width;
      this.coordinate.height = displayList[index].height;
      this.Update_Cropping_Params();

      displayList[index].display.setVideoMode(mode);
      const {
        display,
        width: displayWidth,
        height: displayHeight,
      } = displayList[index];
      if (
        this.Should_Update_Watermark(
          display,
          displayWidth,
          displayHeight,
          displayWidth,
          displayHeight
        )
      ) {
        this.Update_Display_Watermark(
          display,
          displayWidth,
          displayHeight,
          displayWidth,
          displayHeight
        );
      }
      let updateFlag =
        !display ||
        !display.reuse ||
        !displayList[index].rendercanvasID ||
        !this.canvasIdSet.has(displayList[index].rendercanvasID);
      if ([consts.VIDEO_RGBA, consts.VIDEO_BGRA].indexOf(mode) !== -1) {
        displayList[index].display.updateSelfVideoTextures(
          width,
          height,
          data,
          this.croppingParams,
          updateFlag
        );
        if (displayList[index].enableMultiDecodeVideoWithoutSAB) {
          displayList[index].firstFrame = true;
        } else {
          displayList[index].display.drawSelfVideo(
            this.coordinate,
            false,
            this.ismirror
          );
        }
      } else {
        displayList[index].display.updateRemoteVideoTextures(
          width,
          height,
          this.croppingParams,
          data,
          0,
          0,
          this.coordinate,
          false,
          updateFlag
        );
        if (displayList[index].enableMultiDecodeVideoWithoutSAB) {
          displayList[index].firstFrame = true;
        } else {
          displayList[index].display.drawRemoteVideo(
            this.coordinate,
            this.ismirror
          );
        }
      }
      this.canvasIdSet.add(displayList[index].rendercanvasID);
      // break;  //interval mode support draw self as active
    }

    this.renderManager.renderFor(RenderConst.SERVE_FOR.VIDEO);
  } else {
    if (data && ssrc) {
      let ssrcRenderArray = this.FindVideoRenderListFromMap(ssrc);
      this.ssrcHaddataMap.set(ssrc, true);
      let that = this;
      if (ssrcRenderArray.length) {
        this.canvasIdSet.clear();
        ssrcRenderArray.forEach((frameWrapper) => {
          frameWrapper.haddata = true;
          that.coordinate.x = frameWrapper.x;
          that.coordinate.y = frameWrapper.y;
          that.coordinate.width = frameWrapper.width;
          that.coordinate.height = frameWrapper.height;
          that.Update_Cropping_Params();
          let display = frameWrapper.display;
          let updateFlag =
            !display ||
            !display.reuse ||
            !frameWrapper.rendercanvasID ||
            !this.canvasIdSet.has(frameWrapper.rendercanvasID);
          if (display) {
            display.setVideoMode(mode);
            if (
              that.Should_Update_Watermark(
                display,
                frameWrapper.width,
                frameWrapper.height,
                frameWrapper.width,
                frameWrapper.height
              )
            ) {
              that.Update_Display_Watermark(
                display,
                frameWrapper.width,
                frameWrapper.height,
                frameWrapper.width,
                frameWrapper.height
              );
            }
            if ([consts.VIDEO_RGBA, consts.VIDEO_BGRA].indexOf(mode) !== -1) {
              display.updateSelfVideoTextures(
                width,
                height,
                data,
                that.croppingParams,
                updateFlag
              );
            } else {
              display.updateRemoteVideoTextures(
                width,
                height,
                that.croppingParams,
                data,
                0,
                true,
                that.coordinate,
                false,
                updateFlag
              );
            }
            this.canvasIdSet.add(frameWrapper.rendercanvasID);
          }
        });

        this.renderManager.renderFor(RenderConst.SERVE_FOR.VIDEO);
      }
    }
  }
  return;
};

VideoRender.prototype.Draw_Send_Video_Img = function (
  data,
  width,
  height,
  ssrc,
  mode = consts.VIDEO_RGBA,
  cropping = 0
) {
  this.Update_SelfVideo_Texture(width, height, data, ssrc, mode, cropping);
};

VideoRender.prototype.setMirrorMyVideoOption = function (ismirror) {
  this.ismirror = ismirror;
};

VideoRender.prototype.Start_Draw = function (mode, interval_time = 10) {
  //mode 1 represent interval mode;
  //mode 0 represent requestAnimation Mode;
  this.rendingFlag = true;
  this.renderMode = mode;
  if (!mode) {
    set_VideoRenderHandle(this);
    return this.Start_Request_Animation_Frame();
  } else {
    if (interval_time) {
      this.intervalHandle = setInterval(
        this.JsMediaSDK_VideoRender_interval.bind(this),
        interval_time
      );
      return this.intervalHandle;
    }
  }
  return null;
};

VideoRender.prototype.Stop_Draw = function () {
  this.rendingFlag = false;
  if (!this.renderMode) {
    this.Stop_Request_Animation_Frame();
  } else {
    clearInterval(this.intervalHandle);
  }
};

VideoRender.prototype.contextLostCallback = function (event) {
  let canvas = event.currentTarget;
  let canvasId = canvas.canvasId;

  // this.reuseDisplayMap.forEach((value, key) => {
  //   value.recoverTextures();
  //   key.recoverTextures();
  // });
  // this.reuseDisplayMap.clear();

  // if (this.rAFID) {
  //   this.Stop_Request_Animation_Frame();

  //   if (this.onStopDrawCallback) {
  //     this.onStopDrawCallback(this);
  //   }
  // }

  multiview_webgl_monitor(canvasId);
  if (this.monitorLoggerFn) {
    this.monitorLoggerFn('MWGLF');
  }

  event.preventDefault();
};

VideoRender.prototype.contextRestoredCallback = function (event) {
  let canvas = event.currentTarget;
  let canvasId = canvas.canvasId;
  let callback = canvas.callback;

  this.Restored_From_Context_Lost(
    canvas,
    canvas,
    canvasId,
    this.Log_Error.bind(this),
    callback
  );
  if (this.onReStoreCallback) {
    this.onReStoreCallback(this);
  }
};

VideoRender.prototype.webGLContextLostProtect = function (
  canvas,
  oldCanvas,
  canvasId,
  callback = null
) {
  if (oldCanvas && oldCanvas !== canvas) {
    if (oldCanvas.contextLostHandler) {
      oldCanvas.removeEventListener(
        'webglcontextlost',
        oldCanvas.contextLostHandler
      );
    }
    if (oldCanvas.contextRestoredHandler) {
      oldCanvas.removeEventListener(
        'webglcontextrestored',
        oldCanvas.contextRestoredHandler
      );
    }
  }
  if (!canvas || this.bWebglContextLostProtectingMap.get(canvas)) {
    return;
  }

  canvas.canvasId = canvasId;
  canvas.callback = callback;
  if (this.renderMode) return;
  oldCanvas = this.bWebglContextLostProtectingMap.get(canvasId);
  if (oldCanvas) {
    oldCanvas.removeEventListener(
      'webglcontextlost',
      oldCanvas.contextLostHandler
    );
    oldCanvas.removeEventListener(
      'webglcontextrestored',
      oldCanvas.contextRestoredHandler
    );
  }
  this.bWebglContextLostProtectingMap.set(canvasId, canvas);
  canvas.contextLostHandler = this.contextLostCallback.bind(this);
  canvas.contextRestoredHandler = this.contextRestoredCallback.bind(this);

  canvas.addEventListener('webglcontextlost', canvas.contextLostHandler, {
    capture: false,
  });

  canvas.addEventListener(
    'webglcontextrestored',
    canvas.contextRestoredHandler,
    {
      capture: false,
    }
  );
};

/**
 * @description update videoRenderArray when recover from context lost
 */
VideoRender.prototype._Replace_Video_Render_Array_From_Context_LOST =
  function ({ canvas, oldCanvas, newDisplayMap }) {
    for (let i = 0; i < this.videoRenderArray.length; i++) {
      if (
        this.videoRenderArray[i].canvas === oldCanvas ||
        this.videoRenderArray[i].canvas === canvas
      ) {
        if (oldCanvas !== canvas) {
          this.videoRenderArray[i].canvas = canvas;
        }
        const fillMode = this.videoRenderArray[i].display.getFillMode();
        if (newDisplayMap) {
          this.videoRenderArray[i].display = newDisplayMap.get(
            this.videoRenderArray[i].display.getTextureIndex()
          );
        } else {
          this.videoRenderArray[i].display = this._Create_WebGL_Canvas({
            videoRenderArrayIndex: i,
            canvas,
            canvasId: this.videoRenderArray[i].rendercanvasID,
          });
        }
        if (fillMode && this.videoRenderArray[i].display) {
          this.videoRenderArray[i].display.setFillMode(
            fillMode,
            this.videoRenderArray[i].display.getFillModeForResolution()
          );
        }
      }
    }
  };

VideoRender.prototype.Restored_From_Context_Lost = function (
  canvas,
  oldCanvas,
  canvasId,
  logErrorFn,
  callback = null
) {
  if (this.renderMode) {
    this._Replace_Video_Render_Array_From_Context_LOST({
      canvas,
      oldCanvas,
    });
  } else {
    const newRenderDisplayMap = this.renderManager.onRestoredFromContextLost(
      canvasId,
      canvas,
      oldCanvas,
      this.threadNumbs,
      logErrorFn,
      this.canvasToLocalDisplay
    );
    if (newRenderDisplayMap) {
      this.ssrcDisplayMap.forEach((value, key) => {
        value.forEach((frameWrapper) => {
          frameWrapper.haddata = false;
          if (frameWrapper.canvas === oldCanvas) {
            if (oldCanvas !== canvas) {
              frameWrapper.canvas = canvas;
            }

            let oldDisplay = frameWrapper.display;
            frameWrapper.display = newRenderDisplayMap.get(
              oldDisplay.getTextureIndex()
            );
          }
        });
      });

      this._Replace_Video_Render_Array_From_Context_LOST({
        canvas,
        oldCanvas,
        newDisplayMap: newRenderDisplayMap,
      });
      callback?.({
        canvas,
        oldCanvas,
        newDisplayMap: newRenderDisplayMap,
      });

      if (this.rendingFlag) {
        this.Start_Request_Animation_Frame();
      }
    }
  }
};

/** replace canvas when webgl context lost */
VideoRender.prototype.Replace_Canvas = function (canvas, canvasId) {
  let oldCanvas = null;
  this.bWebglContextLostProtectingMap.forEach((canvasItem, id) => {
    if (id === canvasId) {
      oldCanvas = canvasItem;
    }
  });
  if (oldCanvas != canvas) {
    this.bWebglContextLostProtectingMap.delete(canvasId);
    this.webGLContextLostProtect(canvas, oldCanvas, canvasId);
    this.Restored_From_Context_Lost(
      canvas,
      oldCanvas,
      canvasId,
      this.Log_Error.bind(this)
    );
  }
  if (
    this.isSupportOffscreenCanvas &&
    (this.waterMarkCanvas || this.videorendercanvas)
  ) {
    this.waterMarkCanvas = this.videorendercanvas = new OffscreenCanvas(
      640,
      360
    );
  }
};

VideoRender.prototype.Get_Display = function (
  canvas,
  canvasId,
  ssrc,
  callback = null
) {
  const isWebglRenderer = this.renderManager
    .getRendererProvider()
    .isWebGLRendererType();

  const isWebgl2Renderer = this.renderManager
    .getRendererProvider()
    .isWebGL2RendererType();

  if (isWebglRenderer || isWebgl2Renderer) {
    this.webGLContextLostProtect(canvas, null, canvasId, callback);
  }

  const display = this.renderManager.getVideoRenderDisplay(
    canvas,
    canvasId,
    this.threadNumbs,
    this.Log_Error.bind(this),
    this.canvasToLocalDisplay
  );

  // if ((isWebglRenderer || isWebgl2Renderer) && display && ssrc) {
  //   let ssrcRenderArray = this.ssrcDisplayMap.get(ssrc);
  //   if (ssrcRenderArray !== undefined) {
  //     for (let index = 0; index < ssrcRenderArray.length; index += 1) {
  //       if (canvasId && ssrcRenderArray[index].rendercanvasID == canvasId) {
  //         display.yTextureRef = ssrcRenderArray[index].display.yTextureRef;
  //         display.uTextureRef = ssrcRenderArray[index].display.uTextureRef;
  //         display.vTextureRef = ssrcRenderArray[index].display.vTextureRef;
  //         display.reuse = ssrcRenderArray[index].display.reuse = true;
  //         this.reuseDisplayMap.set(ssrcRenderArray[index].display, display);
  //       }
  //     }
  //   }
  // }

  return display;
};

VideoRender.prototype.GiveBack_Display = function (data, clearCanvas = true) {
  let obseleteData = data[0];
  if (!obseleteData || !obseleteData.display) {
    return;
  }

  this.renderManager.recycleRenderDisplay(
    obseleteData.canvas,
    obseleteData.display,
    clearCanvas
  );

  // const isWebglRenderer = this.renderManager
  //   .getRendererProvider()
  //   .isWebGLRendererType();

  // const isWebgl2Renderer = this.renderManager
  //   .getRendererProvider()
  //   .isWebGL2RendererType();

  // if (isWebglRenderer || isWebgl2Renderer) {
  //   obseleteData.display.recoverTextures();
  //   let reuseDisplay = this.reuseDisplayMap.get(obseleteData.display);
  //   if (reuseDisplay) {
  //     reuseDisplay.recoverTextures();
  //     this.reuseDisplayMap.delete(obseleteData.display);
  //   }
  // }
};

VideoRender.prototype.Get_Logical_SSrc = function (ssrc) {
  if (ssrc) {
    return (ssrc >> 10) << 10;
  }
  return -1;
};

VideoRender.prototype.Set_LocalVideo_Ptr = function (ptr) {
  if (ptr) {
    this.localVideoPtr = ptr;
  }
  return;
};

VideoRender.prototype.Set_LocalVideo_Buffer = function (sab) {
  if (sab) {
    this.encodedVideoSharedArrayWasmMemory = sab;
    this.sabBuffer = sab.buffer;
    this.encodedVideoSharedArrayBufferUint8Array = new Uint8Array(
      this.sabBuffer
    );
    this.encodedVideoSharedArrayBufferUint16Array = new Uint16Array(
      this.sabBuffer
    );
  }
  return;
};

VideoRender.prototype.Set_LocalVideo_SSRC = function (ssrc) {
  if (ssrc) {
    this.localvideossrc = ssrc;
    this.selfvideossrc = ssrc;
  }
  return;
};

VideoRender.prototype.Draw_LocalVideo = function () {
  if (this.localVideoPtr && this.sabBuffer && this.localvideossrc) {
    if (
      this.encodedVideoSharedArrayWasmMemory &&
      this.encodedVideoSharedArrayWasmMemory.buffer != this.sabBuffer
    ) {
      this.encodedVideoSharedArrayBufferUint8Array = new Uint8Array(
        this.encodedVideoSharedArrayWasmMemory.buffer
      );
      this.encodedVideoSharedArrayBufferUint16Array = new Uint16Array(
        this.encodedVideoSharedArrayWasmMemory.buffer
      );
      this.sabBuffer = this.encodedVideoSharedArrayWasmMemory.buffer;
    }
    let rIndex = Atomics.load(
      this.encodedVideoSharedArrayBufferUint8Array,
      this.localVideoPtr
    );
    let wIndex = Atomics.load(
      this.encodedVideoSharedArrayBufferUint8Array,
      this.localVideoPtr + 1
    );
    let flag = true;
    if (rIndex == wIndex) {
      flag = false;
    }

    if (flag) {
      let length = 0;
      let width =
        this.encodedVideoSharedArrayBufferUint16Array[
          this.localVideoPtr / 2 +
            (4 + (consts.VIDEO_DATA_MAX_SIZE + 4 + 2 * 4 + 2) * rIndex) / 2
        ];
      let height =
        this.encodedVideoSharedArrayBufferUint16Array[
          this.localVideoPtr / 2 +
            (4 + (consts.VIDEO_DATA_MAX_SIZE + 4 + 2 * 4 + 2) * rIndex + 2) / 2
        ];
      //cropping
      let rgnvalid_top =
        this.encodedVideoSharedArrayBufferUint16Array[
          this.localVideoPtr / 2 +
            (4 + (consts.VIDEO_DATA_MAX_SIZE + 4 + 2 * 4 + 2) * rIndex + 4) / 2
        ];
      let rgnvalid_left =
        this.encodedVideoSharedArrayBufferUint16Array[
          this.localVideoPtr / 2 +
            (4 + (consts.VIDEO_DATA_MAX_SIZE + 4 + 2 * 4 + 2) * rIndex + 6) / 2
        ];
      let rgnvalid_width =
        this.encodedVideoSharedArrayBufferUint16Array[
          this.localVideoPtr / 2 +
            (4 + (consts.VIDEO_DATA_MAX_SIZE + 4 + 2 * 4 + 2) * rIndex + 8) / 2
        ];
      let rgnvalid_height =
        this.encodedVideoSharedArrayBufferUint16Array[
          this.localVideoPtr / 2 +
            (4 + (consts.VIDEO_DATA_MAX_SIZE + 4 + 2 * 4 + 2) * rIndex + 10) / 2
        ];

      let mode =
        this.encodedVideoSharedArrayBufferUint16Array[
          this.localVideoPtr / 2 +
            (4 + (consts.VIDEO_DATA_MAX_SIZE + 4 + 2 * 4 + 2) * rIndex + 12) / 2
        ];
      /** mode = VIDEO_BGRA should same as RGBA */
      if (mode && mode !== consts.VIDEO_BGRA) {
        length = (width * height * 3) / 2;
      } else {
        //RGBA
        length = width * height * 4;
      }

      this.croppingParams.top = rgnvalid_top;
      this.croppingParams.left = rgnvalid_left;
      this.croppingParams.height = rgnvalid_height;
      this.croppingParams.width = rgnvalid_width;

      let real_suabaaray =
        this.encodedVideoSharedArrayBufferUint8Array.subarray(
          this.localVideoPtr +
            4 +
            (consts.VIDEO_DATA_MAX_SIZE + 4 + 2 * 4 + 2) * rIndex +
            4 +
            2 * 4,
          this.localVideoPtr +
            4 +
            (consts.VIDEO_DATA_MAX_SIZE + 4 + 2 * 4 + 2) * rIndex +
            4 +
            2 * 4 +
            length
        );
      this.Update_SelfVideo_Texture(
        width,
        height,
        real_suabaaray,
        this.Get_Logical_SSrc(this.localvideossrc),
        mode,
        this.croppingParams
      );
      Atomics.store(
        this.encodedVideoSharedArrayBufferUint8Array,
        this.localVideoPtr,
        (rIndex + 1) % 10
      );
    }
  }
};

VideoRender.prototype.Update_LocalVideo = function (dataframe, rotation = 0) {
  let l_ssrc = this.localvideossrc;
  if (this.renderMode) {
    let mode = 0;
    let index = 0;
    let hasLocalSsrc = false;

    let displaylist = this.FindVideoRenderList(l_ssrc);
    for (; index < displaylist.length; index++) {
      hasLocalSsrc = true;
      let message = dataframe;
      this.croppingParams.top = message.visibleRect ? 0 : message.cropTop;
      this.croppingParams.left = message.visibleRect ? 0 : message.cropLeft;
      this.croppingParams.height = message.visibleRect
        ? message.visibleRect.height
        : message.cropHeight;
      this.croppingParams.width = message.visibleRect
        ? message.visibleRect.width
        : message.cropWidth;

      this.coordinate.x = displaylist[index].x;
      this.coordinate.y = displaylist[index].y;
      this.coordinate.width = displaylist[index].width;
      this.coordinate.height = displaylist[index].height;
      this.Update_Cropping_Params();
      let view = displaylist[index].display;
      view.setVideoMode(mode);
      const {
        display,
        width: displayWidth,
        height: displayHeight,
      } = displaylist[index];
      if (displayWidth == 0 || displayHeight == 0) {
        continue;
      }
      if (
        this.Should_Update_Watermark(
          display,
          displayWidth,
          displayHeight,
          displayWidth,
          displayHeight
        )
      ) {
        this.Update_Display_Watermark(
          display,
          displayWidth,
          displayHeight,
          displayWidth,
          displayHeight
        );
      }
      if ([consts.VIDEO_RGBA, consts.VIDEO_BGRA].indexOf(mode) !== -1) {
        displaylist[index].display.updateRemoteVideoTexturesImageBitmap(
          this.croppingParams.width,
          this.croppingParams.height,
          message,
          this.croppingParams,
          message.rotation
        );
        if (displaylist[index].enableMultiDecodeVideoWithoutSAB) {
          displaylist[index].firstFrame = true;
        } else {
          displaylist[index].display.drawSelfVideo(
            this.coordinate,
            false,
            this.ismirror
          );
        }
      }
    }

    if (!hasLocalSsrc) {
      // if length == 0 means the dataframe is not destroyed if is a video frame.
      // so we need to close the video frame under WebGPU case
      this.renderManager.destroyUnusedVideoFrame(dataframe);
    }
  } else {
    this.Update_RemoteVideo_Texture(dataframe, true, l_ssrc, rotation);
  }
};
VideoRender.prototype.Set_Capture_Video_Flag = function (flag) {
  this.startCaptureVideo = flag;
};
VideoRender.prototype.Get_Capture_Video_Flag = function () {
  return this.startCaptureVideo;
};

VideoRender.prototype.FindVideoRender = function (ssrc) {
  if (!ssrc) {
    return null;
  }
  for (let i = 0; i < this.videoRenderArray.length; i++) {
    if (
      this.videoRenderArray[i].ssrc &&
      this.videoRenderArray[i].ssrc >> 10 == ssrc >> 10
    ) {
      return this.videoRenderArray[i];
    }
  }
  return null;
};

VideoRender.prototype.FindVideoRenderList = function (ssrc) {
  let isactivce = this.isActiveSeakerSsrc(ssrc);
  let activeRender = this.FindVideoRender(consts.ACTIVE_SPEAKER_SSRC);
  let renderssrc = [ssrc >> 10];
  if (isactivce && activeRender) {
    renderssrc.push(consts.ACTIVE_SPEAKER_SSRC >> 10);
  }

  let renderList = [];

  for (let i = 0; i < this.videoRenderArray.length; i++) {
    if (
      this.videoRenderArray[i].ssrc &&
      renderssrc.includes(this.videoRenderArray[i].ssrc >> 10)
    ) {
      renderList.push(this.videoRenderArray[i]);
    }
  }
  return renderList;
};

VideoRender.prototype.FindVideoRenderListFromMap = function (ssrc) {
  let isactive = this.isActiveSeakerSsrc(ssrc);

  let ssrcRenderArray =
    this.ssrcDisplayMap.get(this.Get_Logical_SSrc(ssrc)) || [];
  let list = [...ssrcRenderArray];
  if (isactive) {
    let active =
      this.ssrcDisplayMap.get(
        this.Get_Logical_SSrc(consts.ACTIVE_SPEAKER_SSRC)
      ) || [];
    list.push(...active);
  }
  return list;
};

VideoRender.prototype.JsMediaSDK_VideoRender_interval = function () {
  if (this.startCaptureVideo) {
    this.Draw_LocalVideo();
  }

  let activeSpeakeRender1 = this.FindVideoRender(consts.ACTIVE_SPEAKER_SSRC);
  let activeSpeakeRender2 = this.FindVideoRender(this.activeSpeakssrc);

  if (this.videoRenderArray.length != 0) {
    for (let i = 0; i < this.videoRenderArray.length; i++) {
      const frameWrapper = this.videoRenderArray[i];
      const {
        display,
        canvas,
        enableMultiDecodeVideoWithoutSAB,
        ssrc,
        isMyself,
      } = frameWrapper;
      var video_buf = null;

      if (frameWrapper == activeSpeakeRender1 && activeSpeakeRender2) {
        continue;
      }

      let rssrc = ssrc;

      if (frameWrapper == activeSpeakeRender1 && !activeSpeakeRender2) {
        rssrc = this.activeSpeakssrc;
      }

      let displayList = [frameWrapper];

      if (frameWrapper == activeSpeakeRender2 && activeSpeakeRender1) {
        displayList.push(activeSpeakeRender1);
      }

      if (this.isActiveSsrcVideo(rssrc)) {
        let now = Date.now();
        if (this.jsMediaEngine) {
          let timeData = this.jsMediaEngine.Get_Video_SSRC_Latest_Time();
          this.timestamp = timeData.currentSSRCTime;
          this.audioPlayTime = timeData.audioPlayTime;
        }
        this.calPacingTime(now);
        if (now - this.timestart < this.pacingTime) {
        } else {
          video_buf = this.Get_Decoded_Video_Frame(
            this.Get_Logical_SSrc(rssrc)
          );
          this.timestart = now;
        }
      } else {
        video_buf = this.Get_Decoded_Video_Frame(this.Get_Logical_SSrc(rssrc));
      }
      if (video_buf) {
        if (this.isActiveSsrcVideo(rssrc)) {
          this.videoTimestamp = video_buf.ntptime;
        }

        let yuvdata;
        if (video_buf.yuvdata instanceof YuvWrap) {
          yuvdata = video_buf.yuvdata.yuvdata;
        } else {
          yuvdata = video_buf.yuvdata;
        }

        this.croppingParams.top = video_buf.r_y;
        this.croppingParams.left = video_buf.r_x;
        this.croppingParams.height = video_buf.r_h;
        this.croppingParams.width = video_buf.r_w;

        const shouldReverse =
          (video_buf.width > video_buf.height &&
            (video_buf.rotation === 1 || video_buf.rotation === 3)) ||
          (video_buf.width < video_buf.height &&
            video_buf.rotation !== 1 &&
            video_buf.rotation !== 3);
        const watermarkWidth = shouldReverse
          ? video_buf.height
          : video_buf.width;
        const watermarkHeight = shouldReverse
          ? video_buf.width
          : video_buf.height;
        const { width: displayWidth, height: displayHeight } = canvas;

        if (
          this.currentVideoHeight != this.croppingParams.height ||
          this.currentVideoWidth != this.croppingParams.width
        ) {
          if (this.Notify_APPUI) {
            this.Notify_APPUI(jsEvent.CURRENT_VIDEO_RESOLUTION, {
              width: this.croppingParams.width,
              height: this.croppingParams.height,
            });
          } else {
            postMessage({
              status: consts.CURRENT_VIDEO_RESOLUTION,
              width: this.croppingParams.width,
              height: this.croppingParams.height,
            });
          }

          this.currentVideoHeight = this.croppingParams.height;
          this.currentVideoWidth = this.croppingParams.width;
        }

        if (
          yuvdata.length == (video_buf.width * video_buf.height * 3) / 2 &&
          video_buf.r_x >= 0 &&
          video_buf.r_y >= 0 &&
          video_buf.r_x + video_buf.r_w <= video_buf.width &&
          video_buf.r_y + video_buf.r_h <= video_buf.height
        ) {
          if (this.vMonitorCount == 14 * 600) {
            if (this.jsMediaEngine) {
              this.jsMediaEngine.Send_Render_Monitor_Log('VDGLM');
            } else {
              postMessage({
                status: consts.VIDEO_RENDER_MONITOR_LOG,
                data: 'VDGLW',
              });
            }
            this.vMonitorCount = 0;
          }
          this.vMonitorCount++;

          let that = this;
          displayList.forEach((frameWrapper) => {
            if (!frameWrapper.display) {
              return;
            }
            if (
              that.Should_Update_Watermark(
                frameWrapper.display,
                watermarkWidth,
                watermarkHeight,
                displayWidth,
                displayHeight
              )
            ) {
              that.Update_Display_Watermark(
                frameWrapper.display,
                watermarkWidth,
                watermarkHeight,
                displayWidth,
                displayHeight
              );
            }

            if (enableMultiDecodeVideoWithoutSAB) {
              if (frameWrapper.display) {
                frameWrapper.display.setVideoMode(consts.VIDEO_I420);
                frameWrapper.display.updateRemoteVideoTextures(
                  video_buf.width,
                  video_buf.height,
                  that.croppingParams,
                  yuvdata,
                  video_buf.rotation,
                  video_buf.yuv_limited
                );
                // in case firstFrame not coming, rendering wrong
                frameWrapper.firstFrame = true;
              }
            } else {
              frameWrapper.display.setVideoMode(consts.VIDEO_I420);
              frameWrapper.display.drawNextOutputPictureFrame(
                video_buf.width,
                video_buf.height,
                that.croppingParams,
                yuvdata,
                video_buf.rotation,
                video_buf.yuv_limited,
                frameWrapper
              );
            }
          });
        } else {
          this.Log_Error(
            `Invalid YUV data: ${video_buf.r_x} ${video_buf.r_y} ${video_buf.r_w} ${video_buf.r_h} ${video_buf.width} ${video_buf.height}`
          );
        }

        if (video_buf.yuvdata instanceof YuvWrap) {
          video_buf.yuvdata.recycle();
        }
      }
      if (enableMultiDecodeVideoWithoutSAB) {
        displayList.forEach((frameWrapper) => {
          if (enableMultiDecodeVideoWithoutSAB && frameWrapper.firstFrame) {
            const { x, y, width, height } = frameWrapper;
            const coordinate = {
              x,
              y,
              width,
              height,
            };

            if (isMyself) {
              frameWrapper.display.drawSelfVideo(coordinate);
            } else {
              frameWrapper.display.drawRemoteVideo(coordinate);
            }
          }
        });
      }
    }

    this.renderManager.renderFor(RenderConst.SERVE_FOR.VIDEO);
  }
};

VideoRender.prototype.Get_Decoded_Video_Frame = function (ssrc) {
  if (!this.videoQueue) {
    return;
  }
  var ssrc_queue_ = this.videoQueue.GetQueue(ssrc);
  if (ssrc_queue_) {
    var data_node = ssrc_queue_.dequeue();
    return data_node;
  }
  return null;
};

VideoRender.prototype.Set_SSRC_Latest_Time_Stamp = function (
  timestamp,
  audioPlaySsrc = 0
) {
  if (this.timestamp === timestamp || typeof timestamp !== 'number') return;
  if (!timestamp) {
    return;
  }
  this.timestamp = timestamp;
  this.audioPlaySsrc = audioPlaySsrc;
  this.audioPlayTime = Date.now();
};
VideoRender.prototype.Get_Decoded_Video_Buffer_Length = function (ssrc) {
  if (this.videoQueue) {
    return this.videoQueue.GetQueueLength(ssrc);
  }
  return 0;
};
VideoRender.prototype.Get_SSRC_VIDEO_FPS = function (ssrc) {
  ssrc = ssrc >> 10;
  if (this.videoQueue && this.videoQueue.ssrcInfo) {
    var fps = this.videoQueue.ssrcInfo.GetSSRCFpsInfo(ssrc);
    if (fps) {
      return Math.round(fps);
    }
  }
  return 0;
};
VideoRender.prototype.Change_Current_SSRC = function (ssrc) {
  this.currentactive = ssrc;
  this.ClearQueue();
};

VideoRender.prototype.Set_Render_Array_IntervalMode = function (
  videoRenderArray,
  enableMultiDecodeVideoWithoutSAB
) {
  this.videoRenderArray = videoRenderArray;
  if (this.renderMode) {
    if (this.videoRenderArray.length > 0) {
      for (let i = 0; i < this.videoRenderArray.length; i++) {
        const frameWrapper = this.videoRenderArray[i];
        if (enableMultiDecodeVideoWithoutSAB) {
          if (!frameWrapper.display) {
            frameWrapper.enableMultiDecodeVideoWithoutSAB =
              enableMultiDecodeVideoWithoutSAB;
            frameWrapper.display = this.Get_Display(
              frameWrapper.canvas,
              frameWrapper.canvas.id
            );
            if (frameWrapper.display) {
              frameWrapper.display.setFillMode(
                frameWrapper.fillMode,
                frameWrapper.fillModeForResolution
              );
            }
          }
        } else {
          frameWrapper.display = this._Create_WebGL_Canvas({
            videoRenderArrayIndex: i,
            canvas: frameWrapper.canvas,
            canvasId: frameWrapper.rendercanvasID,
          });
          this.webGLContextLostProtect(
            frameWrapper.canvas,
            undefined,
            frameWrapper.rendercanvasID
          );
        }
      }
    }
  }
};

VideoRender.prototype._Create_WebGL_Canvas = function ({
  videoRenderArrayIndex,
  canvas,
  canvasId,
}) {
  const args = {
    forceNoGL: undefined,
    contextOptions: {
      webglcontextlostCallback: this.webglcontextlostCallback.bind(this),
      webglcontextrestoredCallback:
        this.webglcontextrestoredCallback.bind(this),
      params: {
        videoRenderArrayIndex,
        canvas,
        canvasId,
      },
    },
    webGLResources: undefined,
    initMask: false,
  };

  return this.renderManager.createWebGLVideoRenderDisplay(
    canvas,
    canvasId,
    0,
    args
  );
};

VideoRender.prototype.webglcontextlostCallback = function (
  ev,
  { canvas, canvasId }
) {
  ev.preventDefault();
};

VideoRender.prototype.webglcontextrestoredCallback = function (
  ev,
  { videoRenderArrayIndex, canvas, canvasId }
) {
  if (
    !this.videoRenderArray ||
    videoRenderArrayIndex >= this.videoRenderArray?.length
  ) {
    this.Log_Error(
      `videoRenderArray:${this.videoRenderArray}, length:${this.videoRenderArray?.length}`
    );
  }
  if (
    this.renderMode &&
    videoRenderArrayIndex < this.videoRenderArray?.length
  ) {
    this.videoRenderArray[videoRenderArrayIndex].display =
      this._Create_WebGL_Canvas({
        videoRenderArrayIndex,
        canvas,
        canvasId,
      });

    this.Set_Render_Array(this.videoRenderArray);
  }
};

VideoRender.prototype.ClearQueue = function () {
  try {
    let map = this.videoQueue.ssrcQueueMap;
    for (let [k, v] of map) {
      while (!v.isEmpty()) {
        let item = v.dequeue();
        if (item.yuvdata && item.yuvdata instanceof YuvWrap) {
          item.yuvdata.recycle();
        }
      }
    }
  } catch (e) {
    this.Log_Error('Exception during VideoRender.ClearQueue', e);
  }

  if (this.videoQueue) {
    this.videoQueue.ClearQueue();
  }
  this.currentVideoHeight = 0;
  this.currentVideoWidth = 0;
};

VideoRender.prototype.Set_WaterMark_Info = function ({
  waterMarkCanvas,
  isCreateVideoWaterMark,
  videoWaterMarkName,
  watermarkOpacity,
  watermarkRepeated,
  watermarkPosition,
}) {
  if (waterMarkCanvas) {
    //firefox dont support offscreen canvas
    this.waterMarkCanvas = waterMarkCanvas;
  } else {
    this.waterMarkCanvas = this.videorendercanvas;
  }
  this.isCreateVideoWaterMark = isCreateVideoWaterMark;
  this.videoWaterMarkName = videoWaterMarkName;
  if (watermarkRepeated !== undefined) {
    this.isWaterMarkRepeatedEnable = !!watermarkRepeated;
  }
  if (watermarkOpacity !== undefined) {
    this.waterMarkOpacity = watermarkOpacity;
  }
  if (watermarkPosition !== undefined) {
    this.watermarkPosition = watermarkPosition;
  }
};

VideoRender.prototype.Set_WaterMark_Flag = function (enable) {
  if (this.videoRenderArray.length > 0) {
    for (let i = 0; i < this.videoRenderArray.length; i++) {
      const { display } = this.videoRenderArray[i];
      display.setWatermarkFlag(enable ? 1 : 0);
    }
  }
};

VideoRender.prototype.Should_Watermark_Repeated = function (
  displayWidth,
  displayHeight
) {
  return (
    this.isWaterMarkRepeatedEnable && displayWidth > 306 && displayHeight > 202
  );
};

const getHigherQualitySize = function (width, height) {
  if (width < 640 && width) {
    const zoom = 640 / width;
    width = 640;
    height = Math.round(height * zoom);
  }
  return { width, height };
};

VideoRender.prototype.Update_Display_Watermark = function (
  display,
  width,
  height,
  displayWidth,
  displayHeight
) {
  const shouldRepeated = this.Should_Watermark_Repeated(
    displayWidth,
    displayHeight
  );
  if (!this.waterMarkCanvas && !this.videorendercanvas) {
    this.videorendercanvas = this.isSupportOffscreenCanvas
      ? new OffscreenCanvas(640, 360)
      : null;
  }

  /** follow native client, if video size too small, always render watermark in middle */
  const position =
    displayWidth < 512 || displayHeight < 288 ? 16 : this.watermarkPosition;

  const waterMarkCanvas = this.waterMarkCanvas || this.videorendercanvas;

  if (
    typeof OffscreenCanvas === 'function' &&
    waterMarkCanvas instanceof OffscreenCanvas &&
    OffscreenCanvasRenderingContext2D &&
    !OffscreenCanvasRenderingContext2D.prototype.measureText
  ) {
    return;
  }

  const highQualitySize = getHigherQualitySize(width, height);
  width = highQualitySize.width;
  height = highQualitySize.height;

  const watermarkData = shouldRepeated
    ? this.WaterMarkRGBA.Get_Repeated_WaterMarkRGBA({
        canvas: waterMarkCanvas,
        name: this.videoWaterMarkName,
        width,
        height,
        opacity: this.waterMarkOpacity,
        position,
      })
    : this.WaterMarkRGBA.Get_WaterMarkRGBA({
        canvas: waterMarkCanvas,
        name: this.videoWaterMarkName,
        width,
        height,
        opacity: this.waterMarkOpacity,
        position,
      });

  display.updateWatermark(width, height, watermarkData);
};

/**
 * use displayWidth & displayHeight determine repeated, use width & height draw watermark.
 * Because for firefox sometimes receving video width changes.
 */
VideoRender.prototype.Should_Update_Watermark = function (
  display,
  width,
  height,
  displayWidth,
  displayHeight
) {
  if (!this.isCreateVideoWaterMark) return false;
  let shouldUpdate = false;
  const highQualitySize = getHigherQualitySize(width, height);
  if (
    highQualitySize.width !== display.getWatermarkWidth() ||
    highQualitySize.height !== display.getWatermarkHeight()
  ) {
    shouldUpdate = true;
  }
  const isWatermarkRepeated = this.Should_Watermark_Repeated(
    displayWidth,
    displayHeight
  );
  /** have no watermark yet */
  if (!display.isSetWatermark()) {
    shouldUpdate = true;
  }
  /** switch between repeated and no repeated */
  if (isWatermarkRepeated !== display.isWatermarkRepeated()) {
    shouldUpdate = true;
    display.setWatermarkRepeated(isWatermarkRepeated);
  }
  if (
    this.waterMarkOpacity &&
    this.waterMarkOpacity !== display.getWatermarkOpacity()
  ) {
    shouldUpdate = true;
    display.setWatermarkOpacity(this.waterMarkOpacity);
  }
  /** follow native client, if video size too small, always render watermark in middle */
  const position = width < 512 || height < 288 ? 16 : this.watermarkPosition;
  if (position !== display.getWatermarkPosition()) {
    shouldUpdate = true;
    display.setWatermarkPosition(position);
  }
  return shouldUpdate;
};

VideoRender.prototype.calCulateActiveFps = function (frameMilliTime) {
  if (this.activeFps && frameMilliTime >= this.lfTimeStamp) {
    this.activeFps =
      500 / (frameMilliTime - this.lfTimeStamp) + this.activeFps / 2;
  } else if (!!this.lfTimeStamp) {
    this.activeFps = 1000 / (frameMilliTime - this.lfTimeStamp);
  }
};
VideoRender.prototype.Put_Video_Data_Queue = function (
  message,
  maxQuqueBufferLen = 30
) {
  if (this.videoQueue) {
    var ssrcqueue_ = this.videoQueue.GetQueue(message.ssrc);
    if (!ssrcqueue_) {
      ssrcqueue_ = this.videoQueue.AddQueue(message.ssrc);
    }
    ssrcqueue_.enqueue(message);
    if (
      this.Get_Logical_SSrc(this.currentactive) ==
      this.Get_Logical_SSrc(message.ssrc)
    ) {
      if (this.lfTimeStamp) {
        this.calCulateActiveFps(message.ntptime);
      }
      this.lfTimeStamp = message.ntptime;
    } else if (
      this.Get_Logical_SSrc(this.audioPlaySsrc) ==
      this.Get_Logical_SSrc(message.ssrc)
    ) {
      if (this.lfTimeStamp) {
        this.calCulateActiveFps(message.ntptime);
      }
      this.lfTimeStamp = message.ntptime;
    }
    if (this.lastActiveSSRC != message.ssrc) {
      this.lfTimeStamp = 0;
      this.activeFps = 0;
    }
    this.lastActiveSSRC = message.ssrc;
    if (this.videoQueue && this.videoQueue.ssrcInfo) {
      var ssrc_node_part = message.ssrc >> 10;
      this.videoQueue.ssrcInfo.UpdateSSRCInfo(ssrc_node_part, message.ntptime);
    }
    var videoqueuelength = this.videoQueue.GetQueueLength(message.ssrc);
    var diff = videoqueuelength - maxQuqueBufferLen;
    while (diff >= 0) {
      let videoBuf = this.Get_Decoded_Video_Frame(message.ssrc);
      if (videoBuf.yuvdata instanceof YuvWrap) {
        videoBuf.yuvdata.recycle();
      }
      diff--;
    }
  } else {
    if (message.yuvdata && message.yuvdata instanceof YuvWrap) {
      message.yuvdata.recycle();
    }
  }
};

VideoRender.prototype.Set_VideoTrackReader = function (
  isSupportVideoTrackReader
) {
  this.isSupportVideoTrackReader = isSupportVideoTrackReader;
};

VideoRender.prototype.Clear_OffScreenCanvas = function (canvas) {
  this.renderManager.clearOffscreenCanvas(canvas);
  if (canvas.contextLostHandler) {
    canvas.removeEventListener('webglcontextlost', canvas.contextLostHandler);
    canvas.contextLostHandler = null;
  }
  if (canvas.contextRestoredHandler) {
    canvas.removeEventListener(
      'webglcontextrestored',
      canvas.contextRestoredHandler
    );
    canvas.contextRestoredHandler = null;
  }

  canvas.width = 1;
  canvas.height = 1;
};

VideoRender.prototype.Zoom_Render = function (data) {};

VideoRender.prototype.Decode_Ssrc = function (ssrc) {
  return (
    this.ssrcDisplayMap.has(this.Get_Logical_SSrc(ssrc)) ||
    this.Get_Logical_SSrc(ssrc) == this.Get_Logical_SSrc(this.selfvideossrc)
  );
};

const isInWorker = typeof window === 'undefined';
VideoRender.prototype.Check_Request_Animation = function () {
  if (isInWorker && this.rendingFlag && this.prevRequestAnimationTimestap) {
    const now = performance.now();
    const duration = now - this.prevRequestAnimationTimestap;
    if (duration > 5000 && !this.rAFIDBack) {
      postMessage({
        status: MONITOR_MESSAGE,
        data: 'WCL_M,RCIF',
      });

      if (process.env.NODE_ENV === 'development') {
        console.error('in development mode: RequestAnimation is broken');
      }

      if (this.rAFID) {
        cancelAnimationFrame(this.rAFID);
        this.rAFID = 0;
      }
      this.rAFIDBack = setInterval(No_Bindthis_RAF.bind(null, true), 30);
    }
  }
};

VideoRender.prototype.Start_Request_Animation_Frame = function (
  isBackupMode,
  forceRequestAnimation
) {
  if (!isBackupMode && this.rAFIDBack) {
    console.log('Request_Animation_start');
    this.Stop_Request_Animation_Frame_Backup();
  }
  if (this.rAFIDBack) {
    return;
  }
  if (!forceRequestAnimation) {
    this.retryRequestAnimationStep = 2;
  }
  this.prevRequestAnimationTimestap = performance.now();
  this.rAFID = requestAnimationFrame(No_Bindthis_RAF);
  return this.rAFID;
};

VideoRender.prototype.Stop_Request_Animation_Frame_Backup = function () {
  if (this.rAFIDBack) {
    clearInterval(this.rAFIDBack);
    this.rAFIDBack = null;
  }
};

VideoRender.prototype.Stop_Request_Animation_Frame = function () {
  if (this.rAFID) {
    cancelAnimationFrame(this.rAFID);
  }
  this.rAFID = 0;
  this.prevRequestAnimationTimestap = 0;
  this.retryRequestAnimationStep = 2;
  this.Stop_Request_Animation_Frame_Backup();
};

VideoRender.prototype.Log_Error = function (message, errorEvent = null) {
  if (this.globalTracingLogger) {
    // in main thread, log directly
    this.globalTracingLogger.error(message, errorEvent);
  } else {
    // in worker, log via postMessage
    globaltracing_error(message, errorEvent);
  }
};

function No_Bindthis_RAF(isBackupMode) {
  let now = Date.now();
  let that = VideoRenderHandle;
  if (now - lastRenerTime < 30) {
    that.Start_Request_Animation_Frame(isBackupMode);
    return;
  }
  lastRenerTime = now;
  if (!that.rendingFlag) {
    if (that.rAFID) {
      that.Stop_Request_Animation_Frame();
    }
    return;
  }

  if (that.needClear) {
    //TODO clear canvas
    that.needClear = false;
  }

  try {
    if (that.startCaptureVideo && that.localVideoPtr) {
      that.Draw_LocalVideo();
    }

    that.ssrcDisplayMap.forEach(function (value, key) {
      if (
        key == that.Get_Logical_SSrc(consts.ACTIVE_SPEAKER_SSRC) &&
        that.ssrcDisplayMap.has(that.Get_Logical_SSrc(that.activeSpeakssrc))
      ) {
        return;
      }

      if (key == that.Get_Logical_SSrc(consts.ACTIVE_SPEAKER_SSRC)) {
        key = that.activeSpeakssrc;
      }

      if (that.isActiveSsrcVideo(key)) {
        that.calPacingTime(now);

        if (now - that.timestart < that.pacingTime) {
        } else {
          that.timestart = now;
          let video_buff = that.Get_Decoded_Video_Frame(
            that.Get_Logical_SSrc(key)
          );
          if (video_buff) {
            that.Update_RemoteVideo_Texture(video_buff);
            that.videoTimestamp = video_buff.ntptime;
          }
        }
      } else {
        let video_buff = that.Get_Decoded_Video_Frame(
          that.Get_Logical_SSrc(key)
        );
        if (video_buff) that.Update_RemoteVideo_Texture(video_buff);
      }
    });

    that.ssrcDisplayMap.forEach(function (value, key) {
      let isSelfVideo = that.isSelfVideo(key);
      value.forEach((frameWrapper) => {
        let display = frameWrapper.display;
        if (!display?.isAvaiable?.()) {
          setTimeout(() => {
            display?.restoreContext?.();
          }, 5);
        }

        if (!frameWrapper.haddata) {
          return;
        }
        that.coordinate.x = frameWrapper.x;
        that.coordinate.y = frameWrapper.y;
        that.coordinate.width = frameWrapper.width;
        that.coordinate.height = frameWrapper.height;

        if (display) {
          if (display.getVideoMode() != consts.VIDEO_INVALID) {
            if (
              isSelfVideo &&
              [consts.VIDEO_RGBA, consts.VIDEO_BGRA].indexOf(
                display.getVideoMode()
              ) !== -1
            ) {
              display.drawSelfVideo(that.coordinate, false, that.ismirror);
            } else {
              if (isSelfVideo) {
                display.drawRemoteVideo(that.coordinate, that.ismirror);
              } else {
                display.drawRemoteVideo(that.coordinate);
              }
            }
          }
        }
      });
    });

    that.renderManager.renderFor(RenderConst.SERVE_FOR.VIDEO);
  } catch (e) {
    that.Log_Error('Exception while rendering video', e);
  }

  that.Start_Request_Animation_Frame(isBackupMode);
}

VideoRender.prototype.calPacingTime = function (now) {
  this.pacingTime = 30;

  if (this.activeFps >= 1 && this.activeFps <= 40) {
    this.pacingTime = 1000 / this.activeFps;
  }

  //calculate diff between audio and video
  if (!!this.timestamp && !!this.videoTimestamp) {
    let audioNowTime = this.timestamp + now - this.audioPlayTime;
    let deltaTime = this.videoTimestamp + this.pacingTime - audioNowTime;
    let checkssrc = this.currentactive
      ? this.currentactive
      : this.audioPlaySsrc;
    let queueLength = this.videoQueue.GetQueueLength(
      this.Get_Logical_SSrc(checkssrc)
    );
    if (deltaTime > 65 && deltaTime < 10000 && queueLength >= 1) {
      this.pacingTime = this.pacingTime * 1.5;
    }
    if (deltaTime < -65) {
      this.pacingTime = (this.pacingTime * 1) / 2;
    }
  } else if (!this.timestamp) {
    if (this.pacingTime > 150) {
      this.pacingTime = (this.pacingTime * 1) / 2;
    } else {
      this.pacingTime = this.pacingTime - 10;
    }
  }
};

VideoRender.prototype.getRenderManager = function () {
  return this.renderManager;
};

VideoRender.prototype.cleanup = function (
  canvas,
  display,
  needToRecycle = true
) {
  if (process.env.NODE_ENV === 'development') {
    console.log(
      `VideoRender.cleanup canvas=${canvas}, display=${display}, needToRecycle=${needToRecycle}`
    );
  }

  this.renderManager.cleanup(canvas, display, needToRecycle);
};

VideoRender.prototype.setActiveSpeakerSsrc = function (ssrc) {
  this.activeSpeakssrc = ssrc;
};

VideoRender.prototype.getActiveSpeakerSsrc = function (ssrc) {
  return this.activeSpeakssrc;
};

VideoRender.prototype.isActiveSeakerSsrc = function (ssrc) {
  return this.activeSpeakssrc && this.activeSpeakssrc >> 10 == ssrc >> 10;
};
VideoRender.prototype.isActiveSsrcVideo = function (ssrc) {
  let temp = ssrc >> 10;
  return temp == this.currentactive >> 10 || temp == this.audioPlaySsrc >> 10;
};
VideoRender.prototype.clearDraw = function (RGBA) {
  if (RGBA) {
    this.clearColor[0] = RGBA.R * 255;
    this.clearColor[1] = RGBA.G * 255;
    this.clearColor[2] = RGBA.B * 255;
    this.clearColor[3] = RGBA.A * 255;
  }

  this.needClear = true;
};

VideoRender.prototype.isSelfVideo = function (ssrc) {
  return (
    this.Get_Logical_SSrc(ssrc) == this.Get_Logical_SSrc(this.selfvideossrc) ||
    (this.isActiveSeakerSsrc(this.selfvideossrc) &&
      ssrc >> 10 == consts.ACTIVE_SPEAKER_SSRC >> 10)
  );
};

function set_VideoRenderHandle(handle) {
  if (handle) {
    VideoRenderHandle = handle;
    return true;
  }
  return false;
}

var VideoRenderHandle;
var lastRenerTime = 0;
export default VideoRender;
// export default set_VideoRenderHandle;
