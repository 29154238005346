import { GPU_RES_TYPE, RES_OCCUPANCY_LEVEL } from './RenderConst';

/**
 * GPUTexturePool is simple pool to manage GPUTextures.
 * It doesn't create any GPUTexture, you can only acquire or recycle a GPUTexture.
 */
class GPUTexturePool {
  #mResType = GPU_RES_TYPE.TEXTURE;
  #availablePool = [];
  #inUsedPool = [];

  constructor() {}

  /**
   * Acquire an available GPUTexture.
   *
   * @param {*} texConfig texture configuration that has width and height, etc
   * @returns an available GPUTexture or null
   */
  acquire(texConfig) {
    let texture = null;
    const index = this.#availablePool.findIndex(
      (texture) =>
        texture &&
        texture.width == texConfig.w &&
        texture.height == texConfig.h &&
        texture.format == texConfig.format &&
        texture.usage == texConfig.usage
    );

    if (index > -1) {
      texture = this.#availablePool.splice(index, 1)[0];
    }

    if (texture) {
      this.#inUsedPool.push(texture);
    }

    return texture;
  }

  /**
   * Recycle a GPUTexture and make it available again.
   *
   * @param {*} texture a GPUTexture which is recycled
   * @param {boolean} [destroy=false] if true, the texture should be destroyed, otherwise, recycle it
   */
  recycle(texture, destroy = false) {
    // remove it from the inUsePool first
    const index = this.#inUsedPool.indexOf(texture);
    if (index != -1) {
      this.#inUsedPool.splice(index, 1);
    }

    if (destroy) {
      texture.destroy();
    } else {
      // recycle it to the available pool
      this.#availablePool.push(texture);

      // clear its label
      texture.label = '';
    }
  }

  /**
   * Push an available GPUTexture to the pool which contains available GPUTextures.
   *
   * @param {*} availableTexture an available GPUTexture
   */
  pushToAvailablePool(availableTexture) {
    if (!availableTexture) {
      return;
    }
    this.#availablePool.push(availableTexture);
  }

  /**
   * Push an available GPUTexture to the pool which contains in-used GPUTextures.
   * It means the available GPUTexture is in-used and not available now.
   *
   * @param {*} availableTexture an available GPUTexture
   */
  pushToInUsePool(availableTexture) {
    if (!availableTexture) {
      return;
    }
    this.#inUsedPool.push(availableTexture);
  }

  /**
   * Release some available textures by the occupancy level.
   *
   * @param {*} level occupancy level for releasing textures
   */
  release(level) {
    if (level == RES_OCCUPANCY_LEVEL.OVERUSE) {
      if (this.#availablePool.length > 0) {
        for (const tex of this.#availablePool) {
          tex.destroy();
        }
        this.#availablePool.length = 0;
      }
    }
  }

  /**
   * Destroy all the GPUTextures and clear all the pools.
   */
  cleanup() {
    for (const tex of this.#availablePool) {
      tex.destroy();
    }

    for (const tex of this.#inUsedPool) {
      tex.destroy();
    }

    this.#availablePool.length = 0;
    this.#inUsedPool.length = 0;
  }

  getAvailablePool() {
    return this.#availablePool;
  }

  getInUsedPool() {
    return this.#inUsedPool;
  }

  getResourceType() {
    return this.#mResType;
  }
}

export default GPUTexturePool;
