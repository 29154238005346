import GPUBufferManager from './GPUBufferManager';
import GPUTextureManager from './GPUTextureManager';
import GPUBufferPool from './GPUBufferPool';
import GPUFeaturesHelper from './GPUFeaturesHelper';
import GPUResourcesWatchDog from './GPUResourcesWatchDog';
import { isBufferSizeOverMaxSize } from './GPURenderUtils';
import * as RenderConst from './RenderConst';
import { globaltracing_error } from '../../worker/common/common';

/**
 * WebGPUResManager will provide some key components while the renderer
 * is WebGPU renderer.
 *
 * For example, WebGPUResManager will not provide a new GPUDevice while a
 * created GPUDevice is working, the cached GPUDevice will be returned when
 * you want one.
 */
class WebGPUResManager {
  #mGPUAdapter = null;
  #mGPUAdapterInfo = null;
  #mGPUDevice = null;
  #mCanvasFormat = null;
  #mGPUBufferMgr = null;
  #mGPUTextureMgr = null;
  #mGPUBufferPool = null;
  #mGPUFeaturesHelper = null;
  #mGPUResWatchDog = null;
  #mRendererProvider = null;
  #mLabel = '';

  constructor() {}

  addRendererProviderModule(rendererProvider) {
    this.#mRendererProvider = rendererProvider;
  }

  setLabel(label) {
    this.#mLabel = label;
  }

  async initialize() {
    if (!navigator.gpu) {
      console.error(`[WebGPUResManager] initialize() WebGPU is not supported!`);
      return;
    }

    if (!this.#mGPUAdapter) {
      this.#mGPUAdapter = await navigator.gpu.requestAdapter();
      if (!this.#mGPUAdapter) {
        console.error(
          "[WebGPUResManager] initialize() Couldn't request WebGPU adapter."
        );

        globaltracing_error(
          `WebGPU device was lost: ${info.message} reason=${info.reason}`
        );
        return;
      }
    }

    if (!this.#mGPUDevice) {
      this.#mGPUDevice = await this.#mGPUAdapter.requestDevice();
      this.#mGPUDevice.lost.then(async (info) => {
        if (info.reason != 'destroyed') {
          console.error(
            `WebGPU device was lost: ${info.message} reason=${info.reason}`
          );
          globaltracing_error(
            `WebGPU device was lost: ${info.message} reason=${info.reason}`
          );
        }

        if (this.#mRendererProvider) {
          this.#mRendererProvider.rendererUnconfigureGPUContext();
        }

        // Many causes for lost devices are transient, so applications should try getting a
        // new device once a previous one has been lost unless the loss was caused by the
        // application intentionally destroying the device. Note that any WebGPU resources
        // created with the previous device (buffers, textures, etc) will need to be
        // re-created with the new one.
        // no matter which kind of reason, to cleanup all the allocated GPU resources if device lost happens.
        this.cleanup();
        if (info.reason != 'destroyed') {
          this.#mGPUDevice = null;
          await this.initialize();
          if (this.#mRendererProvider) {
            this.#mRendererProvider.rendererReinitialize();
          }
        }
      });
    }

    if (!this.#mGPUAdapterInfo) {
      this.#mGPUAdapterInfo = await this.#mGPUAdapter.requestAdapterInfo();
    }

    if (!this.#mCanvasFormat) {
      this.#mCanvasFormat = navigator.gpu.getPreferredCanvasFormat();
    }

    if (!this.#mGPUBufferMgr) {
      this.#mGPUBufferMgr = new GPUBufferManager(this.#mGPUDevice);
    }

    if (!this.#mGPUTextureMgr) {
      this.#mGPUTextureMgr = new GPUTextureManager(this.#mGPUDevice);
    }

    if (!this.#mGPUBufferPool) {
      this.#mGPUBufferPool = new GPUBufferPool(this.#mGPUDevice);
    }

    if (!this.#mGPUFeaturesHelper) {
      this.#mGPUFeaturesHelper = new GPUFeaturesHelper(this.#mGPUAdapter);
    }

    if (!this.#mGPUResWatchDog) {
      this.#mGPUResWatchDog = new GPUResourcesWatchDog(this.#mLabel);
      this.#mGPUResWatchDog.addObservable(this.#mGPUBufferMgr);
      this.#mGPUResWatchDog.addObservable(this.#mGPUTextureMgr);
      this.#mGPUResWatchDog.addObservable(this.#mGPUBufferPool);
      this.#mGPUResWatchDog.monitor();
    }
  }

  acquireGPUDevice() {
    return this.#mGPUDevice;
  }

  acquireCanvasFormat() {
    return this.#mCanvasFormat;
  }

  acquireGPUAdapterInfo() {
    return this.#mGPUAdapterInfo;
  }

  destroyGPUDevice() {
    if (this.#mGPUDevice) {
      this.#mGPUDevice.destroy();
      this.#mGPUDevice = null;
    }
  }

  acquireGPUBufferMgr() {
    return this.#mGPUBufferMgr;
  }

  acquireGPUTextureMgr() {
    return this.#mGPUTextureMgr;
  }

  acquireGPUBufferPool() {
    return this.#mGPUBufferPool;
  }

  acquireGPUFeaturesHelper() {
    return this.#mGPUFeaturesHelper;
  }

  /**
   * Notify modules to destroy and cleanup resources.
   * Note: here is the real place where you need to destroy GPU resources.
   */
  cleanup() {
    if (this.#mGPUBufferMgr) {
      this.#mGPUBufferMgr.cleanup();
      this.#mGPUBufferMgr = null;
    }

    if (this.#mGPUTextureMgr) {
      this.#mGPUTextureMgr.cleanup();
      this.#mGPUTextureMgr = null;
    }

    if (this.#mGPUBufferPool) {
      this.#mGPUBufferPool.cleanup();
      this.#mGPUBufferPool = null;
    }

    if (this.#mGPUFeaturesHelper) {
      this.#mGPUFeaturesHelper.cleanup();
      this.#mGPUFeaturesHelper = null;
    }

    if (this.#mGPUResWatchDog) {
      this.#mGPUResWatchDog.cleanup();
      this.#mGPUResWatchDog = null;
    }

    this.#mRendererProvider = null;
    this.destroyGPUDevice();
  }

  recycleTextureBufferGroup(texLayer, needToRecycle = true) {
    if (texLayer) {
      if (this.#mGPUBufferPool) {
        const bufferGroup = texLayer.getTextureBufferGroup();
        if (bufferGroup && bufferGroup.buffer) {
          if (bufferGroup.bufferArray) {
            bufferGroup.bufferArray = null;
          }

          this.#mGPUBufferPool.recycle(
            bufferGroup.buffer,
            bufferGroup.bufferConfig,
            needToRecycle
          );

          texLayer.destroyTextureBufferGroup(this);
        }
      }
    }
  }

  recycleInUsedGPUBuffers(texLayersMap) {
    for (const [zIndex, texLayers] of texLayersMap) {
      for (const texLayer of texLayers) {
        if (texLayer) {
          const texBufferGroup = texLayer.getTextureBufferGroup();
          if (texBufferGroup && texBufferGroup.buffer) {
            if (texBufferGroup.bufferArray) {
              texBufferGroup.bufferArray = null;
            }

            this.#mGPUBufferPool.recycle(
              texBufferGroup.buffer,
              texBufferGroup.bufferConfig
            );
          } else {
            // const count = this.#mBufferPool.getInUsedPoolCount();
            // console.log(`recycleInUsedGPUBuffers() recycle is skip! inUsedPoolCount=${count}`);
          }
          texLayer.destroyTextureBufferGroup(this);
        }
      }
    }
  }

  /**
   * Request a GPUBuffer from GPUBufferPool.
   *
   * @param {*} bufferConfig config of buffer
   * @returns null or an available(mapped) GPUBuffer
   */
  requestTextureBuffer(bufferConfig) {
    if (!this.#mGPUBufferPool) {
      return null;
    }

    if (isBufferSizeOverMaxSize(this, bufferConfig.size)) {
      globaltracing_error(
        `requestTextureBuffer() a buffer size that exceeds the max size of GPUBuffer is required.(size:${bufferConfig.size})`
      );
      return null;
    }

    const stagingBufUsage = GPUBufferUsage.MAP_WRITE | GPUBufferUsage.COPY_SRC;
    bufferConfig.usage = stagingBufUsage;
    return this.#mGPUBufferPool.acquire(bufferConfig);
  }

  /**
   * Destroy a group of textures or a single texture.
   *
   * @param {*} textureLayer which holds the GPUTexture(s)
   * @param {boolean} [destroy=false] if true, destroy the texture, otherwise, false
   */
  destroyTextureGroup(textureLayer, destroy = false) {
    if (!textureLayer) {
      return;
    }

    const texGroup = textureLayer.getTextureGroup();
    if (!texGroup) {
      return;
    }

    if (!this.#mGPUTextureMgr) {
      globaltracing_error(`destroyTextureGroup() mGPUTextureMgr is undefined!`);
      return;
    }

    const texType = textureLayer.getTextureType();
    if (texGroup) {
      if (texType == RenderConst.TEX_TYPE.GPU_TEX_YUV) {
        this.#mGPUTextureMgr.recycleTexture(texGroup.yPlaneTex, destroy);
        this.#mGPUTextureMgr.recycleTexture(texGroup.uPlaneTex, destroy);
        if (texGroup.vPlaneTex) {
          this.#mGPUTextureMgr.recycleTexture(texGroup.vPlaneTex, destroy);
        }
      } else if (texType == RenderConst.TEX_TYPE.GPU_TEX_RGBA) {
        this.#mGPUTextureMgr.recycleTexture(texGroup, destroy);
      } else {
        if (this.#isTextureGroup(texGroup)) {
          this.#mGPUTextureMgr.recycleTexture(texGroup.yPlaneTex, destroy);
          this.#mGPUTextureMgr.recycleTexture(texGroup.uPlaneTex, destroy);
          if (texGroup.vPlaneTex) {
            this.#mGPUTextureMgr.recycleTexture(texGroup.vPlaneTex, destroy);
          }
        } else {
          this.#mGPUTextureMgr.recycleTexture(texGroup, destroy);
        }
      }

      textureLayer.setTextureGroup(null);
    }
  }

  #isTextureGroup(textureGroup) {
    return textureGroup !== undefined && 'yPlaneTex' in textureGroup;
  }
}

export default WebGPUResManager;
