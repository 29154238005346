/**
 * @description class used to hanlde audio input level
 * @export
 * @class AudioInputLevel
 */
export class AudioInputLevel {
  constructor(options) {
    this.options = {
      analyserFrequent: 100,
      ...(options || {}),
    };
    this.asnTime = performance.now();
    this.audioStream = null;
    this.analyserNodeBufferDataArray = null;
    this.audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    this.destinationNode = new MediaStreamAudioDestinationNode(this.audioCtx);
    this.initAnalyserNode();
    this.lastUILevel = 0;
  }
  /**
   * @description init analyserNode
   * @protected
   * @private
   */
  initAnalyserNode() {
    this.analyserNode = this.audioCtx.createAnalyser();
    this.analyserNode.connect(this.destinationNode);
    this.analyserNode.fftSize = 1024;
  }

  setAudioStream(stream) {
    this.audioStream = stream;
  }

  /**
   * @description start analyze
   * @public
   * @param [deviceId] set current microphone deviceId
   */
  start() {
    if (this.getAnalyzingStatus()) {
      this.stop();
    }
    if (this.getDestroyedStatus())
      return Promise.reject(new Error('instance is destroyed already'));
    return new Promise((resolve) => {
      this.setAnalyzingStatus(true);
      this.sourceNode = this.audioCtx.createMediaStreamSource(this.audioStream);
      this.sourceNode.connect(this.analyserNode);
      this.resumeAudioCtx();
      this.setAnalyzeInterval();
      resolve(true);
    });
  }
  /**
   * @description stop analyze
   */
  stop() {
    this.setAnalyzingStatus(false);
    this.clearAnalyzeInterval();
  }
  /**
   * @description destroy current instance
   */
  destroy() {
    this.stop();
    if (this.analyserNode && this.destinationNode) {
      this.analyserNode.disconnect(this.destinationNode);
    }
    this.setDestroyedStatus(true);
    this.audioCtx.suspend().finally(() => this.audioCtx.close());
    this.audioStream = null;
    this.analyserNodeBufferDataArray = null;
  }
  /**
   * @description interval callback for analyserNode
   * @protected
   * @private
   */
  analyserNodeIntervalCallback() {
    if (!this.analyserNodeBufferDataArray) return;
    this.analyserNode.getFloatTimeDomainData(this.analyserNodeBufferDataArray);
    let { sumRms, absMax } = calculateLevel(
      this.analyserNodeBufferDataArray,
      2
    );
    const UI_level = getR10Level(absMax);
    if (UI_level !== this.lastUILevel) {
      this.lastUILevel = UI_level;
      this.options.analyserCallback(UI_level);
    }
  }
  /**
   * @description set analyserNode interval
   * @protected
   * @private
   */
  setAnalyzeInterval() {
    this.clearAnalyzeInterval();
    const bufferLength = this.analyserNode.fftSize;
    this.analyserNodeBufferDataArray = new Float32Array(bufferLength);
    this.analyserNodeTimer = window.setInterval(
      this.analyserNodeIntervalCallback.bind(this),
      this.options.analyserFrequent
    );
  }
  /**
   * @description clear analyserNode interval
   * @protected
   * @private
   */
  clearAnalyzeInterval() {
    if (this.analyserNodeTimer) {
      window.clearInterval(this.analyserNodeTimer);
      this.analyserNodeTimer = null;
      this.analyserNodeBufferDataArray = null;
    }
  }

  getAnalyzingStatus() {
    return !!this.isAnalyzing;
  }
  setAnalyzingStatus(flag) {
    this.isAnalyzing = !!flag;
  }
  getDestroyedStatus() {
    return !!this.isDestroyed;
  }
  setDestroyedStatus(destroyed) {
    this.isDestroyed = !!destroyed;
  }
  /**
   * @description resume audio ctx state
   */
  resumeAudioCtx() {
    if (this.audioCtx.state !== 'running') {
      this.audioCtx.resume();
    }
  }
}

/* Same as Native audio level indicator */

// Number of bars on the indicator.
// Note that the number of elements is specified because we are indexing it
// in the range of 0-32
const permutation = [
  0, 1, 2, 3, 4, 4, 5, 5, 5, 5, 6, 6, 6, 6, 6, 7, 7, 7, 7, 8, 8, 8, 9, 9, 9, 9,
  9, 9, 9, 9, 9, 9, 9,
]; // length 33

/*
	audioLevel: [0-1]
	audioLevel is samples abs_max value normalized within 100ms
	For WebRTC Solution: getting from RTCPSender.getStats() -> type=="media-source"
	For Preview: getting from AudioWorklet process -> calculate 100ms abs_max value
	For Webassembly Solution: get from WebAssembly directly
*/

export function calculateLevel(data, inputAudioChannel) {
  let absMax = 0;
  //10^((x-3)/10): 0dB->-4dB:level = 15,-4dB->-8dB:level = 14,...
  let sumSquare = 0;
  for (let i = 0; i < data.length; i = i + inputAudioChannel) {
    // R16 level
    sumSquare += data[i] * data[i];
    // 0-10 level
    let tmpAbs = Math.abs(data[i]);
    if (tmpAbs > absMax) absMax = tmpAbs;
  }

  let sumRms = sumSquare / data.length / inputAudioChannel;
  absMax = absMax > 1 ? 1 : absMax;
  return { sumRms, absMax };
}

export function getR10Level(absMax) {
  if (typeof absMax !== 'number' || absMax < 0 || absMax > 1) return -1;
  let position = parseInt((absMax * 32768) / 1000);
  // Make it less likely that the bar stays at position 0. I.e. only if
  // its in the range 0-250 (instead of 0-1000)
  if (position == 0 && absMax > 250) {
    position = 1;
  }
  return permutation[position];
}

export function getR16Level(sumRms) {
  let level = 0;
  if (sumRms > 0.1995) {
    //-4dB
    level = 15;
  } else if (sumRms > 0.0794) {
    //-8dB
    level = 14;
  } else if (sumRms > 0.0316) {
    //-12dB
    level = 13;
  } else if (sumRms > 0.0126) {
    //-16dB
    level = 12;
  } else if (sumRms > 0.005) {
    //-20dB
    level = 11;
  } else if (sumRms > 0.002) {
    //-24dB
    level = 10;
  } else if (sumRms > 7.9433e-4) {
    //-28dB
    level = 9;
  } else if (sumRms > 3.1623e-4) {
    //-32dB
    level = 8;
  } else if (sumRms > 1.2589e-4) {
    //-36dB
    level = 7;
  } else if (sumRms > 5.0119e-5) {
    //-40dB
    level = 6;
  } else if (sumRms > 1.9953e-5) {
    //-44dB
    level = 5;
  } else if (sumRms > 7.9433e-6) {
    //-48dB
    level = 4;
  } else if (sumRms > 3.1623e-6) {
    //-52dB
    level = 3;
  } else if (sumRms > 1.2589e-6) {
    //-56dB
    level = 2;
  } else if (sumRms > 5.0119e-7) {
    //-60dB
    level = 1;
  } else {
    level = 0;
  }
  return level;
}
