/***
 * send to worker commands
 */

export const START_MEDIA = 1;
export const CLOSE_MEDIA = 2;
export const CLOSE_WEBSOCKET = 3;
export const CLOSE_WEBTRANSPORT = 4;
export const OPEN_WEBSOCKET = 5;
export const OPEN_WEBTRANSPORT = 6;
export const PUSH_MESSAGE_FROM_MAIN_TO_WORKER = 7;
export const UPDATE_DATACHANNEL_STATUS = 8;
export const UPDATE_MEDIA_PARAMS = 9;
export const USER_NODE_LIST_IN_MAIN_SESSION = 10;
export const QOS_MONITORING = 11;
export const SET_USER_NODE_LIST = 129;
export const HOLD = 130;
export const START_AUDIO_CAPTURE = 131;
