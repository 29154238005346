import { add_monitor } from '../../worker/common/common';

/**
 * It narrows a UV coordinate.
 * For example:
 * 1. uv_coord_1 = 0.6643, f(uv_coord_1, 2) = 0.66
 * 2. uv_coord_2 = 0.6691, f(uv_coord_1, 2) = 0.66
 *
 * @param {*} num a UV coordinate will be narrowed
 * @param {*} digits how many digits after decimal point will be dropped
 * @returns a narrowed UV coordinate
 */
export function narrowUvCoords(num, digits) {
  const multiplier = Math.pow(10, digits);
  return Math.floor(num * multiplier) / multiplier;
}

/**
 * It expands a UV coordinate.
 * For example:
 * 1. uv_coord_1 = 0.6643, f(uv_coord_1, 2) = 0.67
 * 2. uv_coord_2 = 0.6691, f(uv_coord_1, 2) = 0.67
 *
 * @param {*} num a UV coordinate will be expanded
 * @param {*} digits how many digits after decimal point will be expanded
 * @returns a narrowed UV coordinate
 */
export function expandUvCoords(num, digits) {
  const multiplier = Math.pow(10, digits);
  return Math.ceil(num * multiplier) / multiplier;
}

/**
 * Whether the size of width or height exceeds the max 2D dimension size.
 *
 * @param {*} resMgr GPUResourceManager contains the GPUFeatureHelper
 * @param {*} width width of the texture
 * @param {*} height height of the texture
 * @returns if true, width or height exceeds the max size
 */
export function isDimensionsOverMaxDimension2DSize(resMgr, width, height) {
  if (!resMgr || width < 0 || height < 0) {
    throw new Error(
      `isDimensionsOverMaxDimension2DSize() invalid parameters. res=${resMgr}, width=${width}, height=${height}`
    );
  }

  let isOver = false;
  let maxDimension = 0;
  const featuresHelper = resMgr.acquireGPUFeaturesHelper();
  if (featuresHelper) {
    maxDimension = featuresHelper.queryMaxTextureDimension2D();
    if (maxDimension > 0) {
      isOver = width > maxDimension || height > maxDimension;
    }
  }

  if (isOver) {
    console.log(
      `isDimensionsOverMaxDimension2DSize() w:${width} h:${height} max:${maxDimension}`
    );

    add_monitor(
      `isDimensionsOverMaxDimension2DSize() w:${width} h:${height} max:${maxDimension}`
    );
  }

  return isOver;
}

/**
 * Whether the required size of a GPUBuffer exceeds the max GPUBuffer size.
 *
 * @param {*} resMgr GPUResourceManager contains the GPUFeatureHelper
 * @param {*} bufferSize a required buffer size of a GPUBuffer
 * @returns if true, exceeds
 */
export function isBufferSizeOverMaxSize(resMgr, bufferSize) {
  if (!resMgr || bufferSize < 0) {
    throw new Error(
      `isBufferSizeOverMaxSize() invalid parameters. res=${resMgr}, bufferSize=${bufferSize}`
    );
  }

  let isOver = false;
  let maxBufferSize = 0;
  const featuresHelper = resMgr.acquireGPUFeaturesHelper();
  if (featuresHelper) {
    maxBufferSize = featuresHelper.queryMaxBufferSize();
    if (maxBufferSize > 0) {
      isOver = bufferSize > maxBufferSize;
    }
  }

  if (isOver) {
    console.log(
      `isBufferSizeOverMaxSize() bufferSize:${bufferSize} max:${maxBufferSize}`
    );

    add_monitor(
      `isBufferSizeOverMaxSize() bufferSize:${bufferSize} max:${maxBufferSize}`
    );
  }

  return isOver;
}
