var Headset_Whitelist_keyword = [
  'headset',
  'pods',
  'buds',
  'opencomm',
  'arctis',
  'mpow',
  'hyperx cloud',
  'zone wired',
  'aeropex by aftershokz',
  'plt focus',
  'xiberia',
  'pxc 550',
  'tk-hs001',
  'lifechat lx',
  'cisco 56',
];

var Headset_Whitelist_sony = [
  ['wh-', 'wf-', 'wi-'],
  ['1000x', 'ch', 'xb', 'sp', 'h9', 'c2', 'c5', 'c6', 'c1', 'h8', 'c4', 'c3'],
];

var Headset_Whitelist_devices = {
  jabra: [
    'evolve',
    'pro 900',
    'pro 920',
    'pro 93',
    'pro 94',
    'talk',
    'biz',
    'engage',
    'elite',
    'uc voice',
    'link 180',
    'link 2',
    'link 860',
    'link 850',
    'link 400',
    'blueparrott',
  ],
  plantronics: [
    'savi',
    'blackwire',
    'c5',
    'c3',
    'c4',
    'c6',
    'c7',
    'bt',
    'audio',
    'da',
    'encorepro',
    'hw111n',
    'gamecom',
    'apu',
    'voyager',
  ],
  logitech: ['h570', 'h820', 'h650', 'pro x'],
  sennheiser: ['sc', 'mb', 'pc', 'sd', 'dect', 'g4me1', 'audio'],
  sony: ['wh', 'wf', 'mdr'],
  bose: ['qc', 'quiet', 'soundlink', 'soundsport', 'on-ear', 'nc'],
  beats: ['fit', 'flex', 'power', 'solo', 'studio', 'mivi'],
  razer: ['kraken', 'blackshark', 'barracuda', 'nari', 'hammerhead', 'opus'],
  yealink: ['uh', 'wh', 'yh', 'cp'],
  soundcore: ['life', 'liberty', 'spirit', 'strike'],
  addasound: ['sr', 'epic'],
  jbl: ['tune', 'quantum', 'live', 'wave', 't450bt', 'c200', 'everest', 'c310'],
  epos: ['impact', 'pc 8 usb', 'h3', 'adapt'],
  jlab: ['go', 'bt', 'epic', 'studio'],
};

function IsHeadset(name) {
  if (typeof name !== 'string') {
    return false;
  }

  let lowerName = name.toLowerCase();
  /// check headset key word
  for (let i = 0; i < Headset_Whitelist_keyword.length; i++) {
    let v = Headset_Whitelist_keyword[i];
    if (lowerName.includes(v)) {
      return true;
    }
  }

  /// check devices
  let keys = Object.keys(Headset_Whitelist_devices);
  for (let i = 0; i < keys.length; i++) {
    let v = keys[i];
    if (lowerName.includes(v)) {
      let types = Headset_Whitelist_devices[v];
      for (let j = 0; j < types.length; j++) {
        if (lowerName.includes(types[j])) {
          return true;
        }
      }
    }
  }

  /// check sony
  let v0 = Headset_Whitelist_sony[0];
  let v1 = Headset_Whitelist_sony[1];
  let is_v0 = false;
  for (let i = 0; i < v0.length; i++) {
    if (lowerName.includes(v0[i])) {
      is_v0 = true;
      break;
    }
  }

  if (is_v0) {
    for (let i = 0; i < v1.length; i++) {
      if (lowerName.includes(v1[i])) {
        return true;
      }
    }
  }
  return false;
}

export default IsHeadset;
